import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../shared/service/data.service';
import { AuthService } from '../../shared/service/auth.service';
// import { PvoKafInter } from '../../shared/interface/field.interface';
import { HttpService } from '../../shared/service/http.service';

import { SimpleModalService } from 'ngx-simple-modal';
import { PromptComponent } from '../../shared/components/alert/prompt/prompt.component';

interface tmp{
  indexId: string;
  tableLoading: boolean;
  loadingRgba: boolean;
  emptyPage: boolean;
}

@Component({
  selector: 'app-being-checked-pvo',
  templateUrl: './being-checked-pvo.component.html',
  styleUrls: ['./being-checked-pvo.component.scss']
})
export class BeingCheckedPvoComponent {
  // pvoKaf: PvoKafInter[];
  tmp: tmp = {
    indexId: '',
    tableLoading: false,
    loadingRgba: false,
    emptyPage: false
  }
  public settings = {
    // hideHeader: true;
    hideSubHeader: true,
    actions: {
      add: false,  
      edit: true,
      delete: true,
      position: 'none'
    },
    columns: {}
  };
  source;
  table = [];
  tableMain = [];
  user;
  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private SimpleModalService: SimpleModalService,
    private httpService: HttpService
    ) { 
      // var empty = false;
      // dataService.checkFieldLength$.subscribe(length=>{
      //   if (!length) {
      //     empty = true;
      //     this.tmp.emptyPage = true;
      //     this.tmp.tableLoading = false;
      //     this.tmp.loadingRgba = false;
      //   }
      // })
      // if (!empty) {
      // }
      this.initializeVar();
      this.getTable(this.dataService.user, 0);
  }
  ngOnInit(): void {
  }
  initializeVar(){
    this.tmp = {
      indexId: '',
      tableLoading: true,
      loadingRgba: false,
      emptyPage: false
    }
    this.settings = {
      hideSubHeader: true,
      actions: {
        add: false,
        edit: false,
        delete: false,
        position: 'none'
      },
      columns: {}
    };
    this.source = [];
  }
  getTable(user, i){
    this.httpService.getDoc(["checkfield"]).subscribe((dataFi:any) => {
      if (dataFi) {
        const data = dataFi;
        this.tableMain = data;
        var notfound = true;
        Object.keys(data).forEach(async res=>{
            if (user.email === ((data[res])[(data[res]).length-1]).user) {
              console.log('dataFi',data[res])
              notfound = false;
              this.tmp.emptyPage = false;
              this.initializeVar();
              await this.genTableSource(data[res])
            }
        })
        if (notfound) {
          this.tmp.tableLoading = false;
          this.tmp.emptyPage = true;
        }
      }
    }, error=>{
      setTimeout(()=>{
        if (i<10) {
          this.getTable(user, i+1);
        }else{
          alert('#478 Nimadur hato ketti yana bir bor urinib ko\'ring! ' + error)
        }
      }, 1000)
    })
  }
  genTableSource(source){
    var arr = {};
    arr['indexId'] = replaceAll((source[(source.length)-1].indexId), "d", ".");
    arr['addBy'] = (source[(source.length)-1].user)
    source.forEach((dat, idx)=>{
      if (dat.type==="date" && dat.value) {
       arr[`title${idx}`] = `${(dat.value)['day']?(dat.value)['day']+'/':''}${(dat.value)['month']?(dat.value)['month']+'/':''}${(dat.value)['year']?(dat.value)['year']:''}`
      }else if (dat.type!=="file") {
        if (dat.addition && dat.addition === 'link' && dat.value) {
          arr[`title${idx}`] = `<a href="${dat.value}" target="_blank">giper xavola</a>`
        }else{

           arr[`title${idx}`] = dat.value?dat.value:'';
        }
      }else{
        arr[`title${idx}`] = '';  
        if (dat.value) {
          arr[`title${idx}`] = '<div class="file_download">';  
          dat.value.forEach((fet, indexFile)=>{
            arr[`title${idx}`] += `<a href="${fet.url}" target="_blank" class="file_download_link"><img src='assets/images/icon/file-icon.png'></a>`  
          })
          arr[`title${idx}`] += '</div>'
        }
      }
    })
    arr['source'] = source[source.length-1]
    this.source.push(arr);
    this.genTableSet((source[(source.length)-1].indexId));
  }
  genTableSet(indexId){
    indexId = indexId.replace("ddd", "d");
    this.dataService.field$.subscribe(fields=>{
      fields.forEach(field=>{
        if (field) {
          if (field.id === indexId) {
            this.settings.columns['indexId'] = {title: 'Индех', editable: false};
            field.config.forEach((res, idx)=>{
                this.settings.columns[`title${idx}`] = {title: res.label, type:res.label==="file"?'html':(res['addition'] && res['addition']==='link'?'html':'string'), editable: res.label==="file"?false:!res.comment?false:true};
            })
            this.settings.columns['addBy'] = {title: "Қўшди", editable: false}
            this.settings.columns['comment'] = {title: "Шархлар"}
            this.table.push({settings: this.settings, source: this.source})
            this.dataService.checkFieldLength$.next(this.table.length)
            this.tmp.tableLoading = false;
          }
        }
      })
    })
  }
}
 function replaceAll(str, from, to){
    var n = '';
    for(let g of str){
      if (g === from) {
        g = to;  
      }
      n += g;
    }
    return n;
  }
