import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { InvoiceRoutingModule } from './invoice-routing.module';
import { InvoiceComponent } from './invoice.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DynamicFormModule } from "../../shared/dynamic-form/dynamic-form.module";
import { AutocompleteModule } from 'ng2-input-autocomplete';

@NgModule({
  declarations: [
    InvoiceComponent,
  ],
  imports: [
    InvoiceRoutingModule,
    DynamicFormModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    AutocompleteModule.forRoot()
  ],
  providers: [],
  bootstrap: [
  ],
  entryComponents: [
  ]
})
export class InvoiceModule { }
