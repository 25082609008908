<div class="container-fluid" *ngIf="!tmp.loading">
    <div class="row">
        <div class="col-sm-12 col-er">
        	<div class="card col-er-13">
                <div class="card-header">
	                <h5>
	                  Ko'rsatgich yaratish
	                </h5>
	            </div>
        		<div class="card-body">
				  <form cladsss="dynamic-form" [formGroup]="formGenField">
				  		<div class="form-group row">
					        <label for="validationCustom" class="col-xl-3 col-md-4">
					        	Ko'rsatgich nomi:
					        </label>
					        <div class="col-md-8">
						        <input 
						        	class="form-control"
						        	formControlName="title" 
						        	placeholder="Ko'rsatgich nomi" 
						        	type="text"
						        	[ngClass]="{ 'is-invalid': f['title'].errors && f['title'].touched }"
						        >
						        <div class="red" *ngIf="f['title'].errors && f['title'].touched">To'ldirilishi shart</div>
					        </div>
					    </div>
					   	<div class="form-group row">
					        <label for="validationCustom" class="col-xl-3 col-md-4">
					        	Ko'rsatgich id:
					        </label>
					        <div class="col-md-8">
						        <input 
						        	class="form-control"
						        	formControlName="id" 
						        	placeholder="Ko'rsatgich id" 
						        	type="text"
						        	[ngClass]="{ 'is-invalid': f['id'].errors && f['id'].touched }"
						        >
						        <div class="red" *ngIf="f['id'].errors && f['id'].touched">{{(f['id'].errors).id}}</div>
					        </div>
					    </div>
					   	<div class="form-group row">
					        <label for="validationCustom" class="col-xl-3 col-md-4">
					        	Koeffitsient:
					        </label>
					        <div class="col-md-8">
						        <input 
						        	class="form-control"
						        	formControlName="coeff" 
						        	placeholder="Koeffitsient" 
						        	type="number"
						        >
					        </div>
					    </div>
					    <div class="form-group row">
					        <label for="validationCustom" class="col-xl-3 col-md-4">
					        	Qator turi
					        </label>
					        <div class="col-md-8">
				                <select class="form-control digits" formControlName="input" (input)="addInput($event.target.value)">>
				                    <option disabled selected value> -- tanlang -- </option>
				                    <option value="input">text</option>
				                    <option value="inputautocomplete">text autocomplete</option>
				                    <!-- <option value="email">email</option> -->
				                    <!-- <option value="password">parol</option> -->
				                    <option value="radiobutton">radio tugmasi</option>
				                    <option value="date">vaqt</option>
				                    <option value="select">tanlov</option>
				                    <option value="checkbox">belgilash katakchasi</option>
				                    <option value="file">file</option>
				                    <option value="number">raqam</option>
				                    <option value="button">tugma</option>
				                </select>
					        </div>
					    </div>
					    <div formArrayName="fieldItems" *ngIf="formGenField.get('fieldItems')['controls'][0] && tmp.inputSev === 'first'">
						  <div [formGroupName]="0">
						  	<div *ngIf="formGenField.value.input !== 'button' && formGenField.value.input !== 'select'">
						  		<div class="form-group row">
							        <label for="validationCustom" class="col-xl-3 col-md-4">
							        	Qator nomi:
							        </label>
							        <div class="col-md-8">
								        <input 
								        	class="form-control"
								        	formControlName="label" 
								        	placeholder="Qator nomi" 
								        	type="text"
								        >
							        </div>
							    </div>
							    <div *ngIf="formGenField.value.input === 'input' || formGenField.value.input === 'inputautocomplete'">
					    	        <div class="form-group row" >
								        <div class="col-md-8">
						                    <div class="checkbox checkbox-primary checkbox-st-25">
						                        <input id="checkbox-primary-yu-24" formControlName="fileSave" type="checkbox">
						                        <label for="checkbox-primary-yu-24">Fayllarni shu qator nomiga saqlash</label>
						                    </div>
								        </div>
								    </div>
					    	        <div class="form-group row" *ngIf="formGenField.value.input === 'inputautocomplete'">
								        <div class="col-md-8">
						                    <div class="checkbox checkbox-primary checkbox-st-25">
						                        <input id="checkbox-primary-ty-25" formControlName="pvoNames" type="checkbox">
						                        <label for="checkbox-primary-ty-25">O'qituvchilar ismlari</label>
						                    </div>
								        </div>
								    </div>
							    </div>
	    					    <div class="form-group row">
							        <label for="validationCustom0" class="col-xl-3 col-md-4">
							        	Validator
							        </label>
							        <div class="col-md-8">
	        						    <app-multi-select-dropdown
	        						     type="notSubmit"
										 [list]="list"
										 (shareCheckedList)="shareCheckedList($event)" 
										 >
										 <!-- 
										 (shareIndividualCheckedList)="shareIndividualCheckedList($event)"
										  -->
										</app-multi-select-dropdown>
						                <!-- <select class="form-control digits" formControlName="validator">>
						                    <option disabled selected value> -- tanlang -- </option>
						                    <option value="required">To'ldirilishi shart</option>
						                    <option value="onlyText">Faqat harf kirgizadi</option>
						                    <option value="email">Email kirgizadi!</option>
						                </select> -->
							        </div>
							    </div>
						  	</div>
<!-- 						    <div [ngSwitch]="formGenField.value.input">
						    	<div *ngSwitchCase="'text'">
								    <div class="form-group row">
								        <label for="validationCustom" class="col-xl-3 col-md-4">
								        	Ko'rsatkich yorlig'i:
								        </label>
								        <div class="col-md-8">
									        <input 
									        	class="form-control"
									        	formControlName="label" 
									        	placeholder="yorlig'i" 
									        	type="text"
									        >
								        </div>
								    </div>
						    	</div>
						    	<div *ngSwitchCase="'email'">
								    <input formControlName="name" placeholder="Item name">
								    <input formControlName="description" placeholder="Item description">
								    <input formControlName="price" placeholder="Item price">
						    	</div>
						    </div> -->
						  </div>
						</div>
						<div formArrayName="fieldItems" *ngIf="tmp.inputSev === 'select'">
					  		<div [formGroupName]="0" class="form-group row">
						        <label for="validationCustom" class="col-xl-3 col-md-4">
						        	Qator nomi:
						        </label>
						        <div class="col-md-8">
							        <input 
							        	class="form-control"
							        	formControlName="label" 
							        	placeholder="Qator nomi" 
							        	type="text"
							        >
						        </div>
						    </div>
					  		<div class="form-group row">
						        <label for="validationCustom" class="col-xl-3 col-md-4">
						        	Qator tanlovlari:
						        </label>
						        <div class="col-md-8">
									<div
									  *ngFor="let item of formGenField.get('fieldItems')['controls']; let i = index;">
									  <div [formGroupName]="i">
								        <input 
								        	class="form-control"
								        	formControlName="name" 
								        	type="text"
								        >
									  </div>
									</div>
						        </div>
						    </div>
							<div (click)="addField('selectOpt')" class="text-right">tanlov qo'shish</div>
						</div>
						<div class="btn-st-2">
								<button type="submit" class="btn btn-primary" (click)="onSubmit($event)">Qo'shish</button>
								<button type="submit" class="btn btn-primary" [disabled]="!formGenField.valid" (click)="saveCreTabDb()">Tugatish</button>
						</div>
				  </form>
        		</div>
        	</div>
            <div class="card col-er-23">
                <div class="card-header">
                    <h5>
                      {{fieldData.title}}
                    </h5>
                </div>
                <div class="needs-validation card-body" *ngIf="tmp.tableLoading">
                  <dynamic-form [fields]="regConfig" (submit)="submit($event)"></dynamic-form>
                </div>
	        </div>
    </div>
  </div>
</div>  

<div class="lds-wrap" *ngIf="tmp.loading">
	<div class="lds-dual-ring"></div>
</div>