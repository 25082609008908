<div class="modal-body_item">
    <label>Fakultet</label>
    <div class="modal-body_item_sec red">
      <ng-autocomplete
        [data]="departments"
        class="form-control-inner"
        [searchKeyword]="keyword"
        (selected)='selectDepartmentEvent($event)'
        (inputChanged)='changeDepartmentEvent($event)'
        (inputCleared) = 'departmentClearedEvent($event)'
        [itemTemplate]="itemTemplate"
        [initialValue]="selectedData.department?selectedData.department.name:''"
        [notFoundTemplate]="notFoundTemplateRoom"
        id="found_departments"
        #found_departments
      >
      </ng-autocomplete>
      <ng-template #itemTemplate let-item>
        <a [innerHTML]="item.name"></a>
      </ng-template>

      <ng-template #notFoundTemplateRoom let-notFound>
          <div class="red">
            topilmadi! 
        </div>
      </ng-template>            
    </div>
  </div>
  <div class="modal-body_item mtop10" *ngIf="selectedData.department && props&&props.type==='selectable'">
    <label>Yo‘nalish</label>
    <div class="modal-body_item_sec red">
      <ng-autocomplete
        [data]="areas"
        class="form-control-inner"
        [searchKeyword]="keyword"
        (selected)='selectAreaEvent($event)'
        (inputCleared) = 'areaClearedEvent($event)'
        [itemTemplate]="itemTemplate"
        [initialValue]="selectedData.area?selectedData.area:''"
        [notFoundTemplate]="notFoundTemplateArea"
        id="found_area"
        #found_area
      >
      </ng-autocomplete>
      <ng-template #itemTemplate let-item>
        <a [innerHTML]="item.name"></a>
      </ng-template>

      <ng-template #notFoundTemplateArea let-notFound>
          <div class="red">
            topilmadi! 
        </div>
      </ng-template>            
    </div>

  </div>