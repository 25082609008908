<div class="container-fluid" *ngIf="!tmp.tableLoading">
    <div class="card">
        <div class="card-body vendor-table" >
            <div class="card-header">
                <h5>
                  O'qituvchilar reytingi ro'yhatda
                </h5>
            </div>
            <div id="batchDelete" class="custom-datatable vendor-list">
                    <div class="btn-popup pull-right">
                        <button type="button" class="btn btn-primary" (click)="exportexcel('excelTablePvo')">Excelga ko'chirib olish</button>
                    </div>
                <div class="table-responsive">
                        <ng2-smart-table 
                            id="excelTablePvo" 
                            [settings]="settings" 
                            [source]="source"
                        ></ng2-smart-table>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="lds-wrap" *ngIf="tmp.tableLoading">
    <div class="lds-dual-ring"></div>
</div>