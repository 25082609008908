import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../../interface/field.interface";
@Component({
  selector: "app-input",
  template: `
	        <div class="form-group row" [formGroup]="group">
		        <label for="validationCustom{{random}}" class="col-xl-3 col-md-4">
		        	<span *ngFor="let validation of field.validations;">
		        		<span *ngIf="validation.name === 'required'">*</span>
		        	</span>
		        	{{field.label}}
		        </label>
		        <div class="col-md-8">
			        <input 
			        	class="form-control"
			        	[formControlName]="field.name" 
			        	[placeholder]="field.label" 
			        	[type]="field.inputType"
			        	id="validationCustom{{random}}"
			        >
					<ng-container *ngFor="let validation of field.validations;">
				       	<div style="color:red;" *ngIf="group.get(field.name).hasError(validation.name) && group.get(field.name).touched">
					      {{validation.message}}
					    </div>
					</ng-container>
		        </div>
		    </div>
		`,
  styles: []
})
export class InputComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  random: number;
  constructor() {}
  ngOnInit() {this.random = Math.floor(Math.random() * 100);}
}
