import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IndexesDataPvoRoutingModule } from './indexes-data-pvo-routing.module';
import { IndexesDataPvoComponent } from './indexes-data-pvo.component';
import { SharedModule } from '../../shared/shared.module';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { UpdateCitationComponent } from './update-citation.component';

@NgModule({
  declarations: [IndexesDataPvoComponent, UpdateCitationComponent],
  imports: [
    CommonModule,
    IndexesDataPvoRoutingModule,
    SharedModule,
    Ng2SmartTableModule,
  ]
})
export class IndexesDataPvoModule { }
