import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DepartmentsComponent } from './departments.component';
import { DepartmentsRoutingModule } from './departments-routing.module';
import { ReactiveFormsModule } from '@angular/forms';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { RenderEditRowComponent } from './render_components/render-edit-row.component';
import { AddRowComponent } from './modal/add-row.component';
import { EditRowComponent } from './modal/edit-row.component';

@NgModule({
  declarations: [ 
    DepartmentsComponent, 
    RenderEditRowComponent,
    AddRowComponent,
    EditRowComponent
  ],
  imports: [
    CommonModule,
    DepartmentsRoutingModule,
    Ng2SmartTableModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [],
})
export class DepartmentsModule { }
