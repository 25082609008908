export class taxesDB {
    static data = [
        {
            detail: "USASTE-PS6N0",
            tax_schedule: "USASTCITY-6*",
            rate: "10%",
            total_amount: "15.24",
        },
        {
            detail: "USASTE-PS6N0",
            tax_schedule: "USASTCITY-6*",
            rate: "18%",
            total_amount: "5.29",
        },
        {
            detail: "USASTE-PS6N0",
            tax_schedule: "USASTCITY-6*",
            rate: "25%",
            total_amount: "4.78",
        },
        {
            detail: "USASTE-PS6N0",
            tax_schedule: "USASTCITY-6*",
            rate: "40%",
            total_amount: "3.20",
        },
        {
            detail: "USASTE-PS6N0",
            tax_schedule: "USASTCITY-6*",
            rate: "50%",
            total_amount: "4.78",
        },
        {
            detail: "USASTE-PS6N0",
            tax_schedule: "USASTCITY-6*",
            rate: "80%",
            total_amount: "8.4",
        }
    ]
}
