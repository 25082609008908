import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CheckIndBkafComponent } from './check-ind-bkaf.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: CheckIndBkafComponent,
        data: {
          title: "Tekshirish",
          breadcrumb: "Tekshirish"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CheckIndBkafRoutingModule { }
