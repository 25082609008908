<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5>Ta’lim yo‘nalishi qo'shish</h5>
                </div>
                <div class="card-body tab2-card">
                    <div class="red" *ngIf="tmp.error">{{tmp.error}}</div>
                    <form [formGroup]="accountForm" (submit)="onAdd($event)" class="needs-validation user-add" novalida>
                        <div class="form-group row">
                            <label for="validationCustom2" class="col-xl-3 col-md-4">
                                <span>*</span>
                                Shifri
                            </label>
                            <div class="col-md-8">
                                <input
                                    class="form-control"
                                    formControlName="lname"
                                    placeholder="Shifri"
                                    type="text"
                                    id="validationCustom2"
                                    [ngClass]="{'is-invalid': f.lname.touched && f.lname.errors}"
                                >
                                <div class="red" *ngIf="f.lname.touched && f.lname.errors && f.lname.errors.required">Qator to'ldirilishi shart!</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom1" class="col-xl-3 col-md-4">
                                <span>*</span>
                                Nomi
                            </label>
                            <div class="col-md-8">
                                <input
                                    class="form-control"
                                    formControlName="fname"
                                    placeholder="Nomi"
                                    type="text"
                                    id="validationCustom1"
                                    [ngClass]="{'is-invalid': f.fname.touched && f.fname.errors}"
                                >
                                <div class="red" *ngIf="f.fname.touched && f.fname.errors && f.fname.errors.required">Qator to'ldirilishi shart!</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom4" class="col-xl-3 col-md-4">
                                <span>*</span>
                                Fakultet
                            </label>
                            <div class="col-md-8">
                                <select class="form-control digits" formControlName="fakul">
                                    <option disabled selected value> -- tanlash -- </option>
                                    <option *ngFor="let item of tizim">{{item.fakultet}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="accountForm.value.fakul">
                            <label for="validationCustom5" class="col-xl-3 col-md-4">
                                <span>*</span>
                                Kafedra
                            </label>
                            <div class="col-md-8">
                                <select class="form-control digits" formControlName="kafed">
                                    <option disabled selected value> -- tanlash -- </option>
                                    <option *ngFor="let item of tanKafedra().kafedralar">{{item}}</option>
                                </select>
                            </div>
                        </div>
                        <!-- <div class="form-group row">
                            <label for="validationCustom35r836" class="col-xl-3 col-md-4">
                                <span>*</span>Email
                            </label>
                            <div class="col-md-8">
                                <input
                                    class="form-control"
                                    formControlName="email"
                                    placeholder="Email"
                                    type="text"
                                    id="validationCustom35r836"
                                >
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom35fg3486" class="col-xl-3 col-md-4">
                                <span>*</span>Parol
                            </label>
                            <div class="col-md-8">
                                <input
                                    class="form-control"
                                    formControlName="password"
                                    placeholder="Parol"
                                    type="password"
                                    id="validationCustom35fg3486"
                                >
                            </div>
                        </div> -->
                        <div class="pull-right">
                            <button type="submit" class="btn btn-primary" [disabled]="accountForm.invalid">Qo'shish</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="lds-wrap" *ngIf="tmp.loading">
    <div class="lds-dual-ring"></div>
</div>
