import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { RatingRoutingModule } from './rating-routing.module';
import { KafTableComponent } from './kaf-table/kaf-table.component';
import { FakulTableComponent } from './fakul-table/fakul-table.component';
import { TeachersComponent } from './teachers/teachers.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { ByIndicatorComponent } from './by-indicator/by-indicator.component';
import { SharedModule } from '../../shared/shared.module';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ChartistModule } from 'ng-chartist';

@NgModule({
  declarations: [KafTableComponent, FakulTableComponent, TeachersComponent, StatisticsComponent, ByIndicatorComponent],
  imports: [
    CommonModule,
    RatingRoutingModule,
    NgxDatatableModule,
    Ng2SmartTableModule,
    SharedModule,
    ChartistModule
  ]
})
export class RatingModule { }
