import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeAdminRoutingModule } from './home-admin-routing.module';
import { HomeAdminComponent } from './home-admin.component';
import { SharedModule } from '../../shared/shared.module';
import { Ng2SmartTableModule } from 'ng2-smart-table';

@NgModule({
  declarations: [HomeAdminComponent],
  imports: [
    CommonModule,
    HomeAdminRoutingModule,
    SharedModule,
    Ng2SmartTableModule
  ]
})
export class HomeAdminModule { }
