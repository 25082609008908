import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { TeachersRoutingModule } from './teachers-routing.module';
import { ListTeachersComponent } from './list/list.component';
import { CreateTeacherComponent } from './create/create.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GoogleLinkRowComponent } from './list/render/google-link.component';

@NgModule({
  declarations: [ListTeachersComponent, CreateTeacherComponent, GoogleLinkRowComponent],
  imports: [
    CommonModule,
    NgbModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    TeachersRoutingModule,
    FormsModule
  ]
})
export class TeachersModule { }
