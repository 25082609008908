<div class="container-fluid" *ngIf="!tmp.tableLoading">
    <div class="card">
        <div class="card-header" *ngIf="tmp.emptyPage">
            <h5>
              Ma'lumot yo'q
            </h5>
        </div>
        <div class="card-body vendor-table" *ngIf="!tmp.emptyPage">
            <div id="batchDelete" class="custom-datatable vendor-list" *ngFor="let item of table">
                <div class="table-responsive">
                        <ng2-smart-table  
                          [settings]="item.settings" 
                          [source]="item.source"
                          (editConfirm)="onEditConfirm($event, ((item.source)[item.source.length-1]).source)"
                          (deleteConfirm)="onSaveConfirm($event, ((item.source)[item.source.length-1]).source)"
                        ></ng2-smart-table>
                </div>
            </div>
        </div>
    </div>  
</div>
<div class="lds-wrap lds-wrap-rgba" *ngIf="tmp.tableLoading || tmp.loadingRgba">
    <div class="lds-dual-ring" [class.fixed]="tmp.loadingRgba"></div>
</div>