import {Component, Output, EventEmitter, OnInit } from '@angular/core';
import { DataService } from '../../shared/service/data.service';
import { HttpService } from '../../shared/service/http.service';

import { SimpleModalService } from 'ngx-simple-modal';
import { PromptComponent } from '../../shared/components/alert/prompt/prompt.component';
import { map } from 'rxjs/operators';

@Component({
    selector: 'reject-component',
    template: `
	    <div class="d-flex flex-nowrap text-white">
	      <button type="button" class="btn btn-info" (click)="updateCitation()">
	        <span class="fa-sync" *ngIf="!loading"></span>
	        <span class="fa-sync loading_animation" *ngIf="dataService.updateCitationStatus&&loading"></span>
	      </button>
	    </div>
    `,
    styles: [`
	    .d-flex{justify-content:center;}
    	.fa-sync {
		    display: block;
		    width: 20px;
		    height: 20px;
    		background: no-repeat url("./assets/images/icon/sync-solid.svg");
		}
		.loading_animation{
		    -webkit-animation:spin 1s linear infinite;
		    -moz-animation:spin 1s linear infinite;
		    animation:spin 1s linear infinite;
		}
		@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
		@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
		@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
    `]
})
export class UpdateCitationComponent implements OnInit  {

    rowData: any;
    loading: boolean = false;
    pvo_added_id:any;

    @Output() save: EventEmitter<any> = new EventEmitter();

    constructor(
    	public dataService: DataService,
    	private httpService: HttpService,
	    private SimpleModalService: SimpleModalService
    	){
    }
    ngOnInit(){
    }
    async updateCitation(){
    	if(this.dataService.updateCitationStatus)return alert("Boshqa maqola yangilanmoqda, iltimos kutib turing!");
    	this.loading = true;
    	this.dataService.updateCitationStatus = true;
        this.dataService.pvoKaf$.subscribe(pvoData=>{
            pvoData.forEach((pvo, ss, sss)=>{
                var fullName = (pvo.lname + pvo.fname + (pvo.patronymic?pvo.patronymic:''));
                if ((pvo.lname + pvo.fname + (pvo.patronymic?pvo.patronymic:'')).replace(/[ ,.]/g, "") === this.rowData.source.pvoNames.replace(/[ ,.]/g, ""))  {
                    console.log(this.rowData.title5, this.rowData.title6, pvo.added_id, this.rowData.source.added_id)
                 //    this.httpService.googscho_upd_cit(this.rowData.title5, this.rowData.title6, pvo.added_id, this.rowData.source.added_id,)
                	// .subscribe((res:any)=>{
                 //        // console.log('googscho_upd_cit res', res);
                 //        if(res.status === 200){
                 //            // console.log('googscho_upd_cit data', this.rowData.title5, this.rowData.title6, pvo.added_id, this.rowData.source.added_id);
                	//     	this.loading = false;
                 //    		this.rowData.title6 = parseInt(res.cited);
                	//     	// this.dataService.updateCitationData.next(this.rowData);
                 //        }else if(res.status === 199){
                 //            this.loading = false;
                 //        }
                 //            this.dataService.updateCitationData.next(this.rowData);
                	// });
                }
            })
        })
    }
    find_pvo(){

    }
}

