<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Transaction Details</h5>
                </div>
                <div class="card-body">
                    <div id="batchDelete" class="category-table custom-datatable transcation-datatable">
                        <div class="table-responsive">
                            <ng2-smart-table [settings]="settings" [source]="transactions"></ng2-smart-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->