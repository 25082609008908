import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DashboardModule } from './components/dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';
import { ProductsModule } from './components/products/products.module';
import { SalesModule } from './components/sales/sales.module';
import { CouponsModule } from './components/coupons/coupons.module';
import { PagesModule } from './components/pages/pages.module';
import { MediaModule } from './components/media/media.module';
import { MenusModule } from './components/menus/menus.module';
import { VendorsModule } from './components/vendors/vendors.module';
import { TeachersModule } from './components/teachers/teachers.module';
import { LocalizationModule } from './components/localization/localization.module';
import { InvoiceModule } from './components/invoice/invoice.module';
import { SettingModule } from './components/setting/setting.module';;
import { ReportsModule } from './components/reports/reports.module';
import { AuthModule } from './components/auth/auth.module';
import { CreateIndexModule } from './components/create-index/create-index.module';
import { IndexesModule } from './components/indexes/indexes.module';
import { IndexesDataModule } from './components/indexes-data/indexes-data.module';
import { HomeAdminModule } from './components/home-admin/home-admin.module';
import { IndexesDataPvoModule } from './components/indexes-data-pvo/indexes-data-pvo.module';
import { RatingModule } from './components/rating/rating.module';
import { BeingCheckedPvoModule } from './components/being-checked-pvo/being-checked-pvo.module';
import { CheckIndBkafModule } from './components/check-ind-bkaf/check-ind-bkaf.module';

import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestore } from '@angular/fire/firestore';
import { SimpleModalModule } from 'ngx-simple-modal';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from './../environments/environment.prod';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { DepartmentsModule } from './components/departments/departments.module';
import { AreaModule } from './components/area/area.module';
const config: SocketIoConfig = { url: environment.http.socket.path, options: { transports : ['websocket'] } };

import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
// import { MatSliderModule } from '@angular/material/slider';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { JwtInterceptor } from './shared/service/jwt-interceptor.service';
import { AuthGuard } from './guards/auth.guard';
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    DashboardModule,
    InvoiceModule,
    SettingModule,
    ReportsModule,
    AuthModule,
    SharedModule,
    LocalizationModule,
    ProductsModule,
    SalesModule,
    VendorsModule,
    CouponsModule,
    PagesModule,
    MediaModule,
    MenusModule,
    TeachersModule,
    CreateIndexModule,
    IndexesModule,
    IndexesDataModule,
    HomeAdminModule,
    IndexesDataPvoModule,
    RatingModule,
    DepartmentsModule,
    BeingCheckedPvoModule,
    CheckIndBkafModule,
    AreaModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    SimpleModalModule.forRoot({container: "modal-container"}),
    HttpClientModule,
    SocketIoModule.forRoot(config),
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi:true},
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
    constructor(){
    firebase.initializeApp(environment.firebaseConfig);
    // firebase.database();
    // firebase.storage();
  }
}
