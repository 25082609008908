import { Component, OnInit } from '@angular/core';
import {
  trigger,
  transition,
  useAnimation
} from '@angular/animations';

import { slideFadeOut, useSlideFadeInAnimation } from 'src/app/shared/animations/animations';
import { fadeInAndOut } from 'src/app/shared/animations/triggers';
import { DataService } from 'src/app/shared/service/data.service';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { SimpleModalService } from 'ngx-simple-modal';
import { AddRowComponent } from './modal/add-row.component';
import { RenderEditRowComponent } from './render_components/render-edit-row.component';
import { EditRowComponent } from './modal/edit-row.component';
import { ConfirmComponent } from 'src/app/shared/components/modal/confirm/confirm.component'
import { HttpService } from 'src/app/shared/service/http.service';

interface group {
  name: string;
  subsCurr: string;
  groupCap: number;
}
@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss'],
  animations: [
    // The following are pre-built triggers - Use these to add animations with the least work
    fadeInAndOut,
    // The following is a custom trigger using animations from the package
    // Use this approach if you need to customize the animation or use your own states
    trigger('enterFromLeftLeaveToRight', [
      // this transition uses a function that returns an animation with custom parameters
      transition(':enter', useSlideFadeInAnimation('300ms', '20px')),
      // This transition uses useAnimation and passes the parameters directly - accomplishing the same thing as the above function
      transition(':leave', useAnimation(slideFadeOut, {params: {time: '2000ms', endPos: '100px'}})),
    ]),
  ],
})
export class DepartmentsComponent implements OnInit {

  dataSource: LocalDataSource = new LocalDataSource();
  // area = this.dataService.areas;
  loading:boolean=true;
  public settings = {
    pager: {
      display: true
    },
    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'right'
    },
    columns: {
      name: {
        title: 'Fakultet nomi'
      },
      edit: {
        title: 'Harakat',
        type: 'custom',
        renderComponent: RenderEditRowComponent,
        onComponentInitFunction: (instance:any) => {
          instance.save.subscribe((res:any) => {
            if(res.type === 'edit'){
              this.editRowModal(res.rowData);
            }else{
              this.trashRowModal(res.rowData)
            }
          });
        }
      },
    }
  };
  constructor(
    public dataService: DataService,
    private SimpleModalService: SimpleModalService,
    public httpService: HttpService,
    ){

  }
  async trashRowModal(rowData:any){
    const message:any = await this.SimpleModalService.addModal(ConfirmComponent, {
      title: 'O‘chirish', message: 'Qatorni aniq o‘chirmoqchimisiz?'}).toPromise();
    if(!message)return;
    this.loading=true;
    try{
      await this.httpService.removeDepartment(rowData.id)
      this.dataSource['data'] = this.dataSource['data'].filter(res => res.id !== rowData.id);
      this.dataSource.refresh();
    }catch(e:any){
      this.showError();
    }
    this.loading=false;
  }
  async editRowModal(rowData:any){
    const message:any = await this.SimpleModalService.addModal(EditRowComponent, {title: 'Baholash', question: JSON.stringify(rowData)}).toPromise()
        if(message){
          const m = JSON.parse(message);
          console.log(m)
          this.loading=true;
          try{
            await this.httpService.editDepartment(m)
            this.dataSource['data'].map(res => {
              if(res.id === rowData.id){res.name=m.name;} 
            });
            this.dataSource.refresh();
          }catch(error:any){
            this.showError();
          }
        }
        this.loading=false;

  }
    //areas ikkinchi qadam

  async addItem(){
      const message:any = await this.SimpleModalService.addModal(AddRowComponent, {title: 'Baholash', question: 'Ulushni kirgizing'}).toPromise()
        const m = JSON.parse(message);
        if(m){
          try{
            const result:any = await this.httpService.addDepartment(m)
            this.dataSource['data'].unshift(result);
            this.dataSource.refresh();
          }catch(error:any){
            console.log(error)
            this.showError();
          }
        }
  }  
  showError(){
    alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
  }
  ngOnInit() {
    this.loading = true;
    this.dataSource['data'] = this.dataService.departments;
    this.loading = false;
  }

  onDeleteConfirm(event:any){
    console.log('onDeleteConfirm', event)
  }
  onEditConfirm(event:any){
    console.log('onEditConfirm', event)
  }

  openCreateDialog(event:any){

  }

}
