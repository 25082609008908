import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListTeachersComponent } from './list/list.component';
import { CreateTeacherComponent } from './create/create.component';
import { RegisterComponent } from '../users/register/register.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list',
        component: ListTeachersComponent,
        data: {
          title: "Ro\'yxat",
          breadcrumb: "Ro\'yxat"
        }
      },
      {
        path: 'create',
        component: CreateTeacherComponent,
        data: {
          title: "Yaratish",
          breadcrumb: "Yaratish"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TeachersRoutingModule { }
