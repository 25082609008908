import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AreaComponent } from './area.component';
import { AreaRoutingModule } from './area-routing.module';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
  
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { RenderEditRowComponent } from './render_components/render-edit-row.component';
import { AddAreaModalComponent } from './modal/add-area.component';
import { EditRowModalComponent } from './modal/edit-row-modal.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DepartmentsModule } from '../departments/departments.module';
@NgModule({
  declarations: [ 
    AreaComponent, 
    RenderEditRowComponent,
    AddAreaModalComponent,
    EditRowModalComponent,
  ],
  imports: [
    CommonModule,
    AreaRoutingModule,
    Ng2SmartTableModule,
    FormsModule,
    AutocompleteLibModule,
    SharedModule,
    DepartmentsModule
  ],
  providers: [],
})
export class AreaModule { }
