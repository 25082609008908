import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, userInt } from '../../shared/service/auth.service';
import { DataService } from '../../shared/service/data.service';
import { FieldConfig, FieldData } from '../../shared/interface/field.interface';
// import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';
// import { FormBuilder, FormGroup, FormArray, AbstractControl, Validators} from '@angular/forms';
import firebase from "firebase/app";
import "firebase/database";
import { DatePipe } from '@angular/common'
import { HttpService } from '../../shared/service/http.service';
import { SimpleModalService } from 'ngx-simple-modal'; 
import { Update1d5Component } from './modal/update1d5.component';
// import { IndexFormsService, IndexFormsInt } from '../../shared/service/index-forms.service';
// import { filter } from 'rxjs/operators';

// interface tmp{
//   name: string;
//   fileError?: fileErrorInt;
//   loadingFile?: loadingFileInt;
//   loadingStd: boolean;
//   indexId?: string;
// }
// interface fileErrorInt{
//   errorName: string;
// }
interface loadingFileInt{
  loadingFile?: boolean;
  progressFile?: number;
  progressFileAll?: number;
}
interface tmp{
  indexId?: string;
  fieldTitle?: string;
  fieldLoad?: boolean;
  loadingFile?: loadingFileInt;
}
@Component({
  selector: 'app-indexes',
  templateUrl: './indexes.component.html',
  styleUrls: ['./indexes.component.scss']
})
export class IndexesComponent {
    tmp: tmp = {
      fieldLoad: true,
      loadingFile: {
        loadingFile: false,
        progressFile: 0,
        progressFileAll: 0
      },
    }
    files: File[] = [];
    user: userInt;
    field: any;
    fieldConfig: FieldConfig[];
  constructor(
      private route: ActivatedRoute,
      private dataService: DataService,
      private router: Router,
      public datepipe: DatePipe,
      private httpService: HttpService,
      private simpleModalService: SimpleModalService,
      
  ) {
    this.route.paramMap.subscribe(params => {
      this.initializeVar();
      this.tmp.indexId = params.get('id');
      this.getFields(params.get('id'));
    });
      this.user = this.dataService.user;
  }
  update1d5(){
    this.simpleModalService.addModal(Update1d5Component).subscribe(res=>{
      
    })
  }
  initializeVar(){
    this.tmp = {
      fieldLoad: true,
      loadingFile: {
        loadingFile: false,
        progressFile: 0,
        progressFileAll: 0
      },
    }
    this.fieldConfig = [];
  }
  test(){
     this.tmp.fieldLoad = false;

  }
  getFields(indexId){
    this.dataService.field$.subscribe(fields=>{
        var i:boolean = false;
        fields.forEach((field, idx)=>{
          if (field){
            if (field.id === indexId) {
              i = true;
              this.field = field;
              this.fieldConfig = field.config;
              this.tmp.fieldTitle = field.title;
              setTimeout(()=>{
                 this.tmp.fieldLoad = false;
              }, 1000)    
            }
            if ((fields.length === (idx+1) && !i) ) {
              this.router.navigateByUrl('/home');
            }
          }
        })
    })
  }
  submit(fieldValue: any) {
    if (fieldValue.file) {
      this.tmp.loadingFile.loadingFile = true;
      this.monitorUpload(fieldValue.file, 0, [], fieldValue);
    }else{
      this.tmp.fieldLoad = true;
      this.addingArray([],  fieldValue);
    }
  }
  fileType(name){
    if (name.indexOf('jpeg') !== -1) {
      return ".jpeg" 
    }else if(name.indexOf('jpg') !== -1){
      return ".jpg"
    }else if(name.indexOf('png') !== -1){
      return ".png"
    }else if(name.indexOf('pdf') !== -1){
      return ".pdf"
    }else if(name.indexOf('docx') !== -1){
      return ".docx"
    }else if(name.indexOf('doc') !== -1){
      return ".doc"
    }else{
      return "";
    }
  }
  monitorUpload(files, index, filesUrl, fieldValue) {
    var fileItem = files[index];
    const storageRef = firebase.storage().ref();
    var fileSaveName, fileSave = this.fieldConfig.find(res => res.fileSave);
    var random = '_' + (Math.floor(Math.random()*1000)).toString();
    fileSaveName = replaceAll((fileItem.name + random), ".", "")+this.fileType(fileItem.name);
    if (fileSave && (typeof(fieldValue[fileSave['name']]) === "string")) {
      fileSaveName = replaceAll((fieldValue[fileSave['name']]+random), ".", "") + this.fileType(fileItem.name);
    }
    // [STATE_CHANGEDRT storage_upload_handle_error]
    // Create the file metadata
    var metadata = {
      contentType: fileItem.type
    };
    // Upload file and metadata to the object 'images/mountains.jpg'
    var uploadTask = storageRef.child('files/'+ this.tmp.indexId + '/' + fileSaveName).put(fileItem, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.tmp.loadingFile.progressFile = progress/files.length;
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            break;
        }
      }, 
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            console.log('storage/unauthorized')
            break;
          case 'storage/canceled':
            console.log('storage/canceled')
            // User canceled the upload
            break;
          case 'storage/unknown':
            console.log('storage/unknown');
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      }, 
      () => {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          this.tmp.loadingFile.progressFileAll += this.tmp.loadingFile.progressFile;
          filesUrl.push({name: fileItem.name, url: downloadURL, savedName: fileSaveName})
          if (files.length === (index+1)) {
            this.tmp.loadingFile.loadingFile = false;
            this.tmp.fieldLoad = true;
            this.addingArray(filesUrl,  fieldValue);
          }else{
            this.monitorUpload(files, (index+1), filesUrl, fieldValue);
          }
        });
      }
    );
    // [END storage_upload_handle_error]
  }
  addingArray(filesUrl, fieldValue){
    var a, pvoNames, newArray = []; 
    this.fieldConfig.forEach((field, idx)=>{
      if (field.type !== 'button') {
        a = {};
        a['title']=field.label;
        a['value']=fieldValue[`${field.name}`]
        a['type']=field.type;
        if (field.name === "file") {
          a['value'] = filesUrl?filesUrl:'';
        }
        if (field.pvoNames) {
          pvoNames = fieldValue[`${field.name}`];
        }
        if (field['addition']) {
          a['addition']='link';
        }
        newArray.push(a);
      }
    })
    var date=new Date();
    let latest_date =this.datepipe.transform(date, 'dd-MM-yyyy HH:mm');
    newArray.push({created: latest_date, indexId: this.tmp.indexId, user: this.user.email, status: 'pending', comment: '', pvoNames: pvoNames});
    this.saveFormToDb(newArray)
    // return newArray;
  }
  saveFormToDb(arr){
      // console.log('arr ', arr)
      this.httpService.setDocInc(["lastId", "checkfield", "last_id"], ["checkfield"], arr)
      .subscribe((result:any)=>{
        if (result.status === 200) {
          console.log(result)
          alert('Ma\'lumotlar muvaffaqiyatli saqlandi!')
        }else{
          alert('Nimadur hato ketti!')
        }
          this.tmp.fieldLoad = false;
          window.location.reload();
      }, error =>{
        console.error(error);
      })

  }
}
 function replaceAll(str, from, to){
    var n = '';
    for(let g of str){
      if (g === from) {
        g = to;  
      }
      n += g;
    }
    return n;
  }
