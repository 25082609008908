import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { IndexesRoutingModule } from './indexes-routing.module';
import { IndexesComponent } from './indexes.component';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxDropzoneModule } from 'ngx-dropzone';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { DynamicFormModule } from "../../shared/dynamic-form/dynamic-form.module";
import {ProgressBarModule} from "angular-progress-bar"
import { Update1d5Component } from './modal/update1d5.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';

@NgModule({
  declarations: [IndexesComponent,Update1d5Component],
  imports: [
    CommonModule,
    IndexesRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    AutocompleteLibModule,
    NgCircleProgressModule.forRoot({}),
    ProgressBarModule,
    DynamicFormModule,
    Ng2SmartTableModule
  ],
  providers: [DatePipe]
})
export class IndexesModule { }
