import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { KafTableComponent } from './kaf-table/kaf-table.component';
import { FakulTableComponent } from './fakul-table/fakul-table.component';
import { TeachersComponent } from './teachers/teachers.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { ByIndicatorComponent } from './by-indicator/by-indicator.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'kaf-table',
        component: KafTableComponent,
        data: {
          title: "Kafedra reyting",
          breadcrumb: "Kafedra reyting"
        }
      },
      {
        path: 'fakul-table',
        component: FakulTableComponent,
        data: {
          title: "Fakultet reyting",
          breadcrumb: "Fakultet reyting"
        }
      },
      {
        path: 'teachers',
        component: TeachersComponent,
        data: {
          title: "O'qituvchilar reytingi",
          breadcrumb: "O'qituvchilar reytingi"
        }
      },
      {
        path: 'statistics',
        component: StatisticsComponent,
        data: {
          title: "O'qituvchilar statistikasi",
          breadcrumb: "O'qituvchilar statistikasi"
        }
      },
      {
        path: 'by-indicator',
        component: ByIndicatorComponent,
        data: {
          title: "Indikator bo‘yicha statistikasi",
          breadcrumb: "Indikator bo‘yicha statistikasi"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RatingRoutingModule { }
