import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { Router } from '@angular/router'
@Component({
  selector: 'list-button-view',
  template: `
    <div *ngIf="rowData.google_link">
          <a href="{{rowData.google_link}}" target="_blank" >Google</a>
    </div>
  `,
  styles: [`
  `]
})
export class GoogleLinkRowComponent implements ViewCell, OnInit {

  @Input() value: string = '';
  @Input() rowData: any;
  // @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();
  constructor(
    private router: Router,
  ){}
  ngOnInit() {
    console.log(this.rowData.google_link)
  }

}
