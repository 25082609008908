import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BeingCheckedPvoComponent } from './being-checked-pvo.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: BeingCheckedPvoComponent,
        data: {
          title: "Tekshirilayotgan",
          breadcrumb: "Tekshirilayotgan"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BeingCheckedPvoRoutingModule { }
