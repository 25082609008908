//ferpi prod///////////
export const environment = {
  otm: 'ferpi',
  production: true,
  http_auth: 'NEI28rkgun3N#8FE!dk234',
  otm_name: 'Attestatsiya nazorat qilish',
  firebaseConfig: {
    // at_ferpi
    apiKey: 'AIzaSyB16eU45YhQBl5RAM6kReAWZ73dCx6ZGvM',
    authDomain: 'attestatsiyaferpi-2024.firebaseapp.com',
    projectId: 'attestatsiyaferpi-2024',
    storageBucket: 'attestatsiyaferpi-2024.appspot.com',
    messagingSenderId: '950944864179',
    appId: '1:950944864179:web:a832fc2b5bc204d1a89dc6',
    measurementId: 'G-HWBYSE0H3V',
  },
  http: {
    socket: {
      path: 'ws://ferpi.tsnqb.uz/socket.io',
    },
    editArea: {
      path: 'https://at.ferpi.uz/api/system/area',
    },
    addArea: {
      path: 'https://at.ferpi.uz/api/system/area',
    },
    deleteArea: {
      path: 'https://at.ferpi.uz/api/system/area',
    },
    removeDepartment: {
      path: 'https://at.ferpi.uz/api/system/department',
    },
    editDepartment: {
      path: 'https://at.ferpi.uz/api/system/department',
    },
    getDepartmentAArea: {
      path: 'https://at.ferpi.uz/api/system/getAll',
    },
    addDepartment: {
      path: 'https://at.ferpi.uz/api/system/department',
    },
    userRegister: {
      path: 'https://at.ferpi.uz/api/auth/registration',
    },
    recoverPass: {
      path: 'https://at.ferpi.uz/api/mailing/send-mail',
    },
    allRoles: {
      path: 'https://at.ferpi.uz/api/auth/login',
    },
    removeRole: {
      path: 'https://at.ferpi.uz/api/auth/login',
    },
    setRole: {
      path: 'https://at.ferpi.uz/api/auth/login',
    },
    removeTeacher: {
      path: 'https://at.ferpi.uz/api/auth/login',
    },
    allUsers: {
      path: 'https://at.ferpi.uz/api/users/all',
    },
    updateUser: {
      path: 'https://at.ferpi.uz/api/auth/login',
    },
    deleteUser: {
      path: 'https://at.ferpi.uz/api/auth/login',
    },
    login: {
      path: 'https://at.ferpi.uz/api/auth/login',
    },
    autologin: {
      path: 'https://at.ferpi.uz/api/auth/autologin',
    },
    get: {
      //https://tsnqb.ferpi.uz/db_pvorey_test //https://2shahar.uz/tsnqb/get_data.php
      path: 'https://at.ferpi.uz/db/get_data.php',
      file: 'pvorey.txt',
    },
    delete_only_inform_file: {
      path: 'https://at.ferpi.uz/db/delete_only_inform_file.php',
    },
    getFieldsInform: {
      path: 'https://at.ferpi.uz/db/get_fields_inform.php',
    },
    setInc: {
      path: 'https://at.ferpi.uz/db/set_data_inc.php',
      file: 'pvorey.txt',
    },
    del: {
      path: 'https://at.ferpi.uz/db/del_data.php',
      file: 'pvorey.txt',
    },
    setIncDelUpd: {
      path: 'https://at.ferpi.uz/db/set_inc_del_upd.php',
      file: 'pvorey.txt',
    },
    remDocDownGrade: {
      path: 'https://at.ferpi.uz/db/rem_doc_with_down_grade.php',
    },
    googleScholarCited: {
      path: 'https://at.ferpi.uz/db/google_scholar.php',
    },
    googleScholarCitedAll: {
      path: 'https://at.ferpi.uz/db/google_scholar_all.php',
    },
    setNewPvo: {
      path: 'https://at.ferpi.uz/db/set_new_pvo.php',
    },
    removePvo: {
      path: 'https://at.ferpi.uz/db/remove_pvo.php',
    },
    controlIndexStatus: {
      path: 'https://at.ferpi.uz/db/control_index_status.php',
    },
    createAnnounce: {
      path: 'https://at.ferpi.uz/db/create_announce.php',
    },
    getAnnounces: {
      path: 'https://at.ferpi.uz/db/get_announces.php',
    },
    removeAnnounce: {
      path: 'https://at.ferpi.uz/db/remove_announce.php',
    },
    editUserAccaunt: {
      path: 'https://at.ferpi.uz/db/edit_user_account.php',
    },
    createIndex: {
      path: 'https://at.ferpi.uz/db/create_index.php',
    },
  },
  tizim: [
    {
      fakultet: 'Mexanika - mashinasozlik',
      kafedralar: [
        'Mashinasozlik texnologiyasi, jihozlari va mashinasozlikda ishlab chiqarishni avtomatlashtirish',
        'Oliy matematika',
        'Tadbiqiy mexanika',
        'Texnologik mashinalar va jihozlar',
        'Yer usti transport tizimlari va ularni ekspluatatsiyasi',
      ],
    },
    {
      fakultet: 'Energetika',
      kafedralar: [
        'Elektr energetika',
        'Elektr texnikasi, elektr mexanikasi va elektr texnologiyalari',
        'Fizika',
        'Chizma geometriya va muhandislik grafikasi',
      ],
    },
    {
      fakultet: 'Kimyo texnologiya',
      kafedralar: [
        'Kimyo va kimyoviy texnologiya',
        'Neft va neft-gazni qayta ishlash texnologiyasi',
        'Oziq-ovqatlar texnologiyasi',
      ],
    },
    {
      fakultet: 'Qurilish',
      kafedralar: [
        'Bino va inshootlar qurilishi',
        'Muhandislik kommunikatsiyalari qurilishi va montaji',
        'Geodeziya, kartografiya va kadastr',
        'Qurilish materiallari, buyumlari va konstruksiyalarini ishlab chiqarish',
        'Arxitektura',
      ],
    },
    {
      fakultet: 'Ishlab chiqarishda boshqaruv',
      kafedralar: [
        'Iqtisodiyot',
        'Menejment',
        "Tillarni o'rgatish",
        'Buxgalteriya xisobi va audit',
        'O‘zbek tili va tillarni o‘rgatish',
      ],
    },
    {
      fakultet: "Yengil sanoat va to'qimachilik",
      kafedralar: [
        'Yengil sanoat buyumlari texnologiyasi',
        'Qishloq xo‘jalik mahsulotlarini saqlash va dastlabki ishlash texnologiyasi',
        'Tabiiy tolalar',
      ],
    },
    {
      fakultet: 'Kompyuterlashgan loyihalash tizimlari',
      kafedralar: [
        'Elektronika va asbobsozlik',
        'Intellektual muhandislik tizimlari',
        'Metrologiya standartlashtirish va maxsulot sifati menejmenti',
        'Ijtimoiy fanlar va sport',
      ],
    },
  ],
  grastud: 556,
  fakultet: [
    'Mexanika - mashinasozlik',
    'Energetika',
    'Kimyo texnologiya',
    'Qurilish',
    'Ishlab chiqarishda boshqaruv',
    "Yengil sanoat va to'qimachilik",
    'Kompyuterlashgan loyihalash tizimlari',
  ],
  kafedra: [
    'Mashinasozlik texnologiyasi, jihozlari va mashinasozlikda ishlab chiqarishni avtomatlashtirish',
    'Oliy matematika',
    'Tadbiqiy mexanika',
    'Texnologik mashinalar va jihozlar',
    'Yer usti transport tizimlari va ularni ekspluatatsiyasi',
    'Elektr energetika',
    'Elektr texnikasi, elektr mexanikasi va elektr texnologiyalari',
    'Fizika',
    'Chizma geometriya va muhandislik grafikasi',
    'Kimyo va kimyoviy texnologiya',
    'Neft va neft-gazni qayta ishlash texnologiyasi',
    'Oziq-ovqatlar texnologiyasi',
    'Bino va inshootlar qurilishi',
    'Muhandislik kommunikatsiyalari qurilishi va montaji',
    'Geodeziya, kartografiya va kadastr',
    'Qurilish materiallari, buyumlari va konstruksiyalarini ishlab chiqarish',
    'Arxitektura',
    'Iqtisodiyot',
    'Menejment',
    "Tillarni o'rgatish",
    'Buxgalteriya xisobi va audit',
    'O‘zbek tili va tillarni o‘rgatish',
    'Yengil sanoat buyumlari texnologiyasi',
    'Qishloq xo‘jalik mahsulotlarini saqlash va dastlabki ishlash texnologiyasi',
    'Tabiiy tolalar',
    'Elektronika va asbobsozlik',
    'Intellektual muhandislik tizimlari',
    'Metrologiya standartlashtirish va maxsulot sifati menejmenti',
    'Ijtimoiy fanlar va sport',
  ],
};