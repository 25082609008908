import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../../interface/field.interface";
@Component({
  selector: "app-checkbox",
  template: `
	        <div class="form-group row" [formGroup]="group">
		        <div class="col-md-8">
                    <div class="checkbox checkbox-primary">
                        <input id="checkbox-primary-2{{random}}" [formControlName]="field.name" type="checkbox">
                        <label for="checkbox-primary-2{{random}}">{{field.label}}</label>
                    </div>
					<ng-container *ngFor="let validation of field.validations;">
				       	<div style="color:red;" *ngIf="group.get(field.name).hasError(validation.name) && group.get(field.name).touched">
					      {{validation.message}}
					    </div>
					</ng-container>
		        </div>
		    </div>
`,
  styles: []
})
export class CheckboxComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  random: number
  constructor() {}
  ngOnInit() {this.random = Math.floor(Math.random() * 1000);}
}
