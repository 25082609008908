import {Component, Output, EventEmitter, ElementRef, OnInit} from '@angular/core';
import { HttpService } from 'src/app/shared/service/http.service';
import { DataService } from 'src/app/shared/service/data.service';
import { map  } from 'rxjs/operators';
interface tmp{
	indexId: string;
	tableLoading: boolean;
  fieldTitle?: string;
}
@Component({
    selector: 'similarity-component',
    template: `
    	<div (click)="update()"><button>yangilash</button></div>
    `,
    styles: [`
    `]
})
export class UpdateCitiationComponent implements OnInit {

    rowData: any;
    showSimilars:boolean = false;

    @Output() save: EventEmitter<any> = new EventEmitter();

    constructor(
   		private dataService: DataService,
   		private httpService: HttpService,
   		private el: ElementRef
   	){

    }
    ngOnInit(){
    }

    update(){
        this.dataService.updateCitationData.next(this.rowData);
   	}
  //  	onDeleteConfirm(event){}
  //  	closeEventListener(){
		// // console.log('addEventListener')
		// var el = document.getElementById('show-field-close');
		// if(el)el.click();		    		
  //  	}
  //  	async findSimilar(i){
  //  		this.dataService.fieldsInform$.next({indexId: this.rowData.source.indexId, pvoNames: this.rowData.source.pvoNames, added_id: this.rowData.source.added_id});
  //  	}

}
