import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../../interface/field.interface";
@Component({
  selector: "app-number",
  template: `
	        <div class="form-group row" [formGroup]="group">
		        <label for="validationCustom" class="col-xl-3 col-md-4">
		        	<span *ngFor="let validation of field.validations;">
		        		<span *ngIf="validation.name === 'required'">*</span>
		        	</span>
		        	{{field.label}}
		        </label>
		        <div class="col-md-8">
                    <div class="qty-box1 qty-box1_in">
                        <div class="input-group">
                            <i class="fa fa-minus btnGtr1" style="cursor:pointer;" (click)=decrement()></i>
                            <input 
                            	class="touchspin1 text-center" 
                            	(input)="incrementNumber($event)"  
                            	name="counter" 
                            	[value]="counter"
                                type="number"
                            >
                            <i class="fa fa-plus btnLess1" style="cursor:pointer;" (click)=increment()></i>
                        </div>
                    </div>
		        </div>
		    </div>
		`,
  styles: [`
    input[type="file"] {
    height: 100% !important;
	}
	input[type=number]::-webkit-inner-spin-button, 
	input[type=number]::-webkit-outer-spin-button { 
	      -webkit-appearance: none; 
	      margin: 0; 
	}`
	]
})
export class NumberComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  counter: number;
  constructor() {}
  ngOnInit() {this.counter = this.field.value?this.field.value:1}  
  incrementNumber(e){
    const value = parseInt(e.target.value);
    if (value<1) {
      this.counter = 1;
    }else{
      this.counter = value;
    }
  	  this.group.controls[this.field.name].setValue(this.counter);
  }
  increment() {
    this.counter += 1;
  	  // console.log(this.group.get(this.field.name))
  	  this.group.controls[this.field.name].setValue(this.counter);
    // (this.group.get(this.field.name).value) = (1/this.counter); 
  }

  decrement() {
    if (this.counter > 1) {
      this.counter -= 1;
  	  this.group.controls[this.field.name].setValue(this.counter);
      // (<Control>this.group.get(this.field.name).value) = (1/this.counter); 
    }
  }
}
