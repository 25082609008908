<!-- <div class="container-fluid" *ngIf="!tmp.tableLoading">
    <div class="card">
        <div class="card-body vendor-table" >
            <div id="batchDelete" class="custom-datatable vendor-list">
                <div class="table-responsive">
                        <ng2-smart-table  [settings]="settings" [source]="source"></ng2-smart-table>
                </div>
            </div>
        </div>
    </div>
</div> -->
<div class="container-fluid" *ngIf="!tmp.tableLoading">
    <div class="card">
        <div class="card-body vendor-table" >
            <div class="card-header">
                <h5>
                  {{tmp.fieldTitle}}
                </h5>
            </div>
            <div id="batchDelete" class="custom-datatable vendor-list">
                    <div class="btn-popup pull-right">
                        <button *ngIf="tmp.indexId === 'gh6gd5'" type="button" class="btn btn-primary margin-right" (click)="change_link_vs()">Silkani o'zgartirish</button>
                        <button type="button" class="btn btn-primary" (click)="pagerChanged()">Excelga ko'chirib olish</button>
                    </div>
                <div class="table-responsive">
                        <ng2-smart-table 
                            id="excelTable" 
                            [settings]="settings" 
                            [source]="dataSource"
                            (deleteConfirm)="onDeleteConfirm($event)"
                        ></ng2-smart-table>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="lds-wrap" *ngIf="tmp.tableLoading">
	<div class="lds-dual-ring"></div>
</div>
