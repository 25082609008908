import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CheckIndBkafRoutingModule } from './check-ind-bkaf-routing.module';
import { CheckIndBkafComponent } from './check-ind-bkaf.component';
import { SharedModule } from '../../shared/shared.module';
import { Ng2SmartTableModule } from 'ng2-smart-table';

@NgModule({
  declarations: [CheckIndBkafComponent],
  imports: [
    CommonModule,
    CheckIndBkafRoutingModule,
    SharedModule,
    Ng2SmartTableModule
  ]
})
export class CheckIndBkafModule { }
