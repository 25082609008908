import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/shared/service/data.service';
import { HttpService } from 'src/app/shared/service/http.service';
import { ExcelService } from 'src/app/shared/service/excel.service';
import { UpdateCitiationComponent } from './update-citiation.component';
import { PromptComponent } from 'src/app/shared/components/alert/prompt/prompt.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import * as XLSX from 'xlsx';

interface tmp{
	indexId: string;
	tableLoading: boolean;
  fieldTitle?: string;
  change_link?: boolean;
}
@Component({
  selector: 'app-indexes-data',
  templateUrl: './indexes-data.component.html',
  styleUrls: ['./indexes-data.component.scss']
})
export class IndexesDataComponent implements OnInit {


  dataSource: LocalDataSource = new LocalDataSource();
  tmp: tmp = {
  	indexId: '',
  	tableLoading: true,
    change_link: true,
  }
  indexTitles = {};
  source;
  pager = ((localStorage.getItem('pager') === 'true') || ((localStorage.getItem('pager') !== 'false') && (localStorage.getItem('pager') !== 'true')))?true:false;
  public settings = {
    pager: {
      display: this.pager
    },
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<a class="table_del_default_icon"></a>'
    },
    actions: {
      add: false,
      edit: false,
      delete: true,
      position: 'right'
    },
    columns: {}
  }; 
  constructor(
  	private route: ActivatedRoute,
    private dataService: DataService,
    private SimpleModalService: SimpleModalService,
  	private httpService: HttpService,
    private excelService:ExcelService
  	) { 
      if((localStorage.getItem('pager') !== 'false') && (localStorage.getItem('pager') !== 'true')){localStorage.setItem('pager', 'true');}
      this.route.paramMap.subscribe(params=>{
      this.change_link();
   		this.initializeVar();
  		this.tmp.indexId = params.get('id');
  		this.getTable(params.get('id'));
  	})
    this.updateCitation();
  }
  ngOnInit(): void {
  }
  pagerChanged(){
    localStorage.setItem('pager', 'false');
    setTimeout(()=>{window.location.reload();},500)
  }
  updateCitation(){
    this.dataService.updateCitationData
    .subscribe(rowData=>{
      if (rowData) {
        this.onSaveConfirm(rowData);
        // var element = document.getElementsByClassName('loading_animation')[0];
        // if(element)element.classList.remove("loading_animation");
        // this.dataSource['data'].forEach(rowData=>{if (rowData.source.added_id === res.source.added_id)rowData = res;});
        // this.dataSource.refresh();
        // this.dataService.updateCitationStatus = false;
        // alert("Iqtiboslik muvaffaqiyat yangilandi!");
      }
    });

  }
  onSaveConfirm(rowData){
    this.SimpleModalService.addModal(PromptComponent, {
      title: 'Iqtiboslikni yangilash',
      question: 'Iqtiboslikni kirgizing'})
      .subscribe((message) => {
        if (message) {
          if(parseInt(message) < 1){
             return alert('Kirgizilgan iqtiboslik 1 dan katta bo‘lishi kerak!');
          }
          this.dataService.pvoKaf$.subscribe(pvoData=>{
            pvoData.forEach((pvo, ss, sss)=>{
                var fullName = (pvo.lname + pvo.fname + (pvo.patronymic?pvo.patronymic:''));
                if ((pvo.lname + pvo.fname + (pvo.patronymic?pvo.patronymic:'')).replace(/[ ,.]/g, "") === rowData.source.pvoNames.replace(/[ ,.]/g, ""))  {
                  this.httpService.googscho_upd_cit(rowData.title5, rowData.source.total, pvo.added_id, rowData.source.added_id, parseInt(message))
                    .subscribe((res:any)=>{
                        console.log('res', res);
                        if(res.status === 200){
                            this.dataSource['data'].forEach(row=>{if (row.source.added_id === rowData.source.added_id)row['title6'] = parseInt(message);});
                            this.dataSource.refresh();
                            // alert("Iqtiboslik muvaffaqiyat yangilandi!");
                        }else{
                          alert("Iqtiboslikni yangilay olmadim!");
                        }
                  },error=>{
                    console.log(error);
                  });
                }
            })
        })
          // var a = false;
          // this.tmp.loadingRgba = true;
          // this.dataService.pvoKaf$.subscribe(pvoData=>{
          //    pvoData.forEach((pvo, ss, sss)=>{
          //      var fullName = (pvo.lname + pvo.fname + (pvo.patronymic?pvo.patronymic:''));
          //       var arr = this.tableMain.filter((res, ind)=> ind.toString() === source.added_id);
          //       arr = arr[0];
          //       arr[arr.length-1].status = "complete"
          //       arr[arr.length-1]['grade'] = parseFloat(message);
          //       if (event.data.source.pvoNames && (event.data.source.pvoNames).replace(/ /g,'') === fullName.replace(/ /g,'')) {
          //         var obj:any;
          //         if (event.data.source.indexId === "sdfet4") {
          //           obj = {path: `pvoIns/${pvo.added_id}/${source.indexId}`, pvo_added_id:pvo.added_id, grade: parseFloat(message), total: 0};
          //           arr.forEach(res=>{
          //             if (res.title === "iqtiboslar soni") {
          //               obj.total = obj.grade * (res.value?res.value:1);
          //             }
          //           })
          //         }else{
          //           obj = {path: `pvoIns/${pvo.added_id}/${source.indexId}`, pvo_added_id:pvo.added_id, grade: parseFloat(message)};
          //         }
          //         this.saveFormToDb(arr, source.indexId, source.added_id, "fieldsInform", "big", obj);
          //         a = true;
          //       }
          //       if ((sss.length === (ss+1)) && !a) {
          //         this.saveFormToDb(arr, source.indexId, source.added_id, "fieldsInform", "big");
          //       }
          //    })
          // })
        }
      });
  }
  async change_link(){
    const e = await localStorage.getItem('change_link_1d5');
    if(!e){localStorage.setItem('change_link_1d5', 'yes');}
  }
  // async change_link_vs(){
  //   const e = await localStorage.getItem('change_link_1d5');
  //   if (e === 'yes') {await localStorage.setItem('change_link_1d5', 'no')}
  //   else{await localStorage.setItem('change_link_1d5', 'yes');}
  //   window.location.reload();
  // }
  initializeVar(){
  	this.indexTitles = [];
  	this.tmp = {
  		indexId: '',
  	 	tableLoading: true,
      change_link: true
  	}
  	this.settings = {
        pager: {
          display: this.pager
        },
        delete: {
          confirmDelete: true,
          deleteButtonContent: '<a class="table_del_default_icon"></a>'
        },
  	    actions: {
  	      add: false,
  	      edit: false,
  	      delete: true,
  	      position: 'right'
  	    },
  	    columns: {}
  	};
  	this.source = [];
  }
  async change_link_vs(){
    const e = await localStorage.getItem('change_link_1d5');
    if (e === 'yes') {await localStorage.setItem('change_link_1d5', 'no')}
    else{await localStorage.setItem('change_link_1d5', 'yes');}
    window.location.reload();
  }
  //agar o'qituvchi  ochirilsa va uning malumotlar iqolsa ochirish yo'li
  deletePvoNotFoundFiles(){
    let notFound = [];
      this.dataService.pvoKaf$.subscribe(pvoNames=>{
        this.dataSource['data'].map((row, index, array)=>{
          const data  = row.source;
          if(!pvoNames.find((pvo)=>((pvo.lname + pvo.fname + (pvo.patronymic?pvo.patronymic:'')).replace(/[ ,.]/g, "") === data.pvoNames.replace(/[ ,.]/g, "")))){
            notFound.push(data)          
          }
          if(array.length === (index+1)){
            console.log(notFound);
            this.httpFileUnlink(notFound, 0)
          }
        })
      })
  }
  httpFileUnlink(array, index){
    if (array.length !== (index)) {
      this.httpService.delete_only_inform_file(array[index].indexId, array[index].added_id).subscribe((res:any)=>{
        if (res.status===200) {
          console.log('deleted', index, array[index])
          this.httpFileUnlink(array, index+1);
        }
      })
    }
  }
  onDeleteConfirm(event){
    var data = event.data.source;
    console.log(data)
    this.dataService.pvoKaf$.subscribe(pvoNames=>{
      let found = false
      pvoNames.forEach((pvo, index, array)=>{
        if ((pvo.lname + pvo.fname + (pvo.patronymic?pvo.patronymic:''))?.replace(/[ ,.]/g, "") === data.pvoNames?.replace(/[ ,.]/g, ""))  {
          var to_grade = {};
           if (data.indexId === "sdfet4") {
             to_grade = {grade: data.grade, total: (data.grade*data.total)};
           }else{
             to_grade = {grade: data.grade};
           }
          // console.log(pvo.added_id, data.indexId, data.added_id, to_grade)
           this.httpService.remDocDownGrade(pvo.added_id, data.indexId, data.added_id, to_grade)
           .subscribe((res:any)=>{
             // console.log('response', res);
             if (res.status===200) {
                alert('Ma\'lumot muvaffaqiyatli ochirildi!')
                event.confirm.resolve();
             }else{
               alert('#5352 nimadur hato ketti!');
             }
           },error=>{
             alert('error: ' + error);
           })
            found = true;
        }
        if((array.length === (index+1)) && !found){
          console.log('not found', data)
          if(!data.pvoNames){
            this.httpService.remDocDownGrade(null, data.indexId, data.added_id, null)
            .subscribe((res:any)=>{
              // console.log('response', res);
              if (res.status===200) {
                 alert('Ma\'lumot muvaffaqiyatli ochirildi!')
                 event.confirm.resolve();
              }else{
                alert('#5352 nimadur hato ketti!');
              }
            },error=>{
              alert('error: ' + error);
            })
          }
        }
      })
    })
  }
  fileName = 'ExcelSheet.xlsx';
  exportexcel(): void {
    console.log('exportexcel')
     /* table id is passed over here */   
          setTimeout(()=>{
            // this.excelService.exportAsExcelFile(document.getElementById('excelTable').children[0]);
            const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(document.getElementById('excelTable').children[0]);

            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

            XLSX.writeFile(wb, this.fileName);
            setTimeout(()=>{localStorage.setItem('pager', 'true');}, 1000);
          },1000)
  }
  getTable(id){
    this.httpService.getDoc(["fieldsInform", id]).subscribe(data => {
      if (data) {
        // this.updateCitationData(data, (Object.keys(data)).reverse(), 0);
        this.dataService.pvoKaf$.subscribe(pvoNames=>{
          if(pvoNames.length){
            this.genTableSource(data, pvoNames);

          }
        });
      }else{
        this.genTableSet();
      }
    }, error=>{
      setTimeout(()=>{
        this.getTable(id)
      }, 1000)
      console.log(error)
    })

  }
  genTableSet(){
  	this.dataService.field$.subscribe(fields=>{
  		fields.forEach(field=>{
        if (field) {
    			if (field.id === this.tmp.indexId) {
            this.tmp.fieldTitle = field.title;
    				field.config.forEach((res, idx, sss)=>{
              if (res.type !== "button") {
                if (res.type!=="file") {
                  if ((this.tmp.indexId === "sdfet4") && localStorage.getItem('change_link_1d5') === 'yes') {
                      this.settings.columns[`title${idx}`] = {title: res.label, type: 'html'};
                  }else{
      		  				this.settings.columns[`title${idx}`] = {title: res.label};
                  }
    		  			}else{
    		  				this.settings.columns[`title${idx}`] = {title: res.label, type: 'html'};
    		  			}
              }
              if(sss.length === (idx+1)){
                this.settings.columns[`ulush`] = {title: "ulush"};
                if(field.id === 'gh6gd5'){
                  this.settings.columns['updateCitiation'] = {
                    title: 'Iqtiboslikni yangilash',
                    type: 'custom',
                    onComponentInitFunction(instance) {
                      instance.save.subscribe(row => {
                        this.onSaveConfirm(row);
                        // console.log('instance save', row)
                      });
                    },
                    renderComponent: UpdateCitiationComponent
                };
                }

              }else if(idx === 0){
                this.settings.columns[`kafedra`] = {title: "Kafedra"};
              }
    			 	})
            this.dataSource.load(this.source);
  				  this.tmp.tableLoading = false;
            if(localStorage.getItem('pager') === 'false'){
              this.exportexcel();
            }
            // this.deletePvoNotFoundFiles();
    			}
        }
  		})
  	})
  }

  updateCitationData(source, keys, index){
    console.log(index);
        var obj = source[keys[index]];
        this.dataService.pvoKaf$.subscribe(pvoData=>{
            pvoData.forEach((pvo, ss, sss)=>{
                var fullName = (pvo.lname + pvo.fname + (pvo.patronymic?pvo.patronymic:''));
                if ((pvo.lname + pvo.fname + (pvo.patronymic?pvo.patronymic:'')).replace(/[ ,.]/g, "") === obj[7].pvoNames.replace(/[ ,.]/g, ""))  {
                  this.httpService.googscho_upd_cit_all(obj[5].value, (obj[6].value ? obj[6].value : 1), pvo.added_id, obj[7].added_id)
                  .subscribe((res:any)=>{
                        console.log((index/keys.length)*100, '%', res);
                        if(res.status === 200){
                          if(keys.length > index){
                            setTimeout(()=>{
                              this.updateCitationData(source, keys, index + 1);
                            },1000);
                          }
                        }else if(res.status === 199){
                        }
                  },error=>{
                        // setTimeout(()=>{
                        //           this.updateCitationData(source, keys, index);
                        // },1000);
                    console.log('123 ',error)
                  });
                }
            })
        })
  }
  genTableSource(source, pvoNames){
  	(Object.keys(source)).reverse().forEach(res=>{
		var arr = {}, special;
      if (Array.isArray(source[res])) {
    		source[`${res}`].forEach((dat, idx, sss)=>{
    			if (dat.type==="date" && dat.value) {
            arr[`title${idx}`] = `${(dat.value)['day']?(dat.value)['day']+'/':''}${(dat.value)['month']?(dat.value)['month']+'/':''}${(dat.value)['year']?(dat.value)['year']:''}`
          }else if(dat.type==="inputautocomplete" && dat.value){
            arr[`title${idx}`] = dat.value?dat.value:'';
              pvoNames.forEach(pvo=>{
                if ((pvo.lname + pvo.fname + (pvo.patronymic?pvo.patronymic:'')).replace(/[ ,.]/g, "") === dat.value.replace(/[ ,.]/g, ""))  {
                  arr['kafedra'] = pvo.kafed;
                }
              });
          }else if (dat.type!=="file") {
            if ((dat.title === "iqtiboslar soni") && (this.tmp.indexId === "sdfet4")) {
              special = dat.value?dat.value:1;
            }
            if (dat.addition && dat.addition === 'link' && dat.value && localStorage.getItem('change_link_1d5') === 'yes') {
              arr[`title${idx}`] = (localStorage.getItem('pager') === 'true') ? `<a href="${dat.value}" target="_blank">giper xavola</a>` : dat.value;
              if (this.tmp.indexId === "sdfet4") {arr['citiationLink'] = dat.value?dat.value:'';}
            }else{
        			 arr[`title${idx}`] = dat.value?dat.value:'';
               // arr[`title${idx}`] = dat.value?dat.value:'';
            }
    			}else{
  	  			arr[`title${idx}`] = '';	
    				if (dat.value) {
  		  			arr[`title${idx}`] = '<div class="file_download">';	
  	  				dat.value.forEach((fet, indexFile)=>{
  	  					arr[`title${idx}`] += `<a href="${fet.url}" target="_blank" class="file_download_link"><img src='assets/images/icon/file-icon.png'></a>`	
  	  				})
  	  				arr[`title${idx}`] += '</div>'
    				}
    			}
          if(sss.length === (idx+1)){
            arr['ulush'] = dat.grade?dat.grade:'';  
          }
  		  })
        arr['source'] = source[`${res}`][(source[`${res}`].length-1)]
        if (this.tmp.indexId === "sdfet4") {
          arr['source']['total'] = special;
        }
  	  	this.source.push(arr);
      }
  	})
  		this.genTableSet();
  }
  replaceAll(str, from, to){
    var n = '';
    for(let g of str){
      if (g === from) {
        g = to;  
      }
      n += g;
    }
    return n;
  }
}
