import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../../interface/field.interface";
// text,email,tel,textarea,password, 
@Component({
    selector: 'app-file',
    template: `
	    <ngx-dropzone 
			id="ngx-dropzone_1" 
			[maxFileSize]="10000000" 
			class="dropzone-custom dropzone dropzone-primary" 
			accept="application/pdf"
      [multiple]="false"
			(change)="onSelect($event)" 
			>
			<ngx-dropzone-label>
				<div class="dz-message needsclick">
                    <i class="fa fa-cloud-upload"></i>
                    <h4 class="mb-0 f-w-600">Fayllarni bu yerga tashlang yoki yuklash uchun bosing.</h4>
                </div>
			</ngx-dropzone-label>
			<ngx-dropzone-preview *ngFor="let f of files;" [removable]="true" (removed)="onRemove(f)">
				<ngx-dropzone-label style="width: 160px; height: 120px; overflow:hidden;">{{ f.name }}</ngx-dropzone-label>
			</ngx-dropzone-preview>
		</ngx-dropzone>
       	<div class="red fileError" *ngIf="error">
	      {{error}}
	    </div>
    `,
    styles:[
      `
        .dropzone{
			padding: 20px;
		}
		.dropzone-custom{
			margin-bottom: 10px;
			height: 200px;
			overflow-x: initial;
		}
		.flex{
			display: flex;
		}
		.red{
			color: red;
		}
		.fileError{
			padding-bottom: 10px;
		}
		@media only screen and (max-width: 767px){
			.dropzone-custom{
				width: 95%;
				margin: 0 auto 10px auto;
			}
		}
      `
    ]
})
export class FileComponent implements OnInit {
    field: FieldConfig;
    group: FormGroup;
    isHovering;
	files: File[] = [];
	error;
	constructor() {}
	ngOnInit() {}
	toggleHover(e){}
	onSelect(event) {
	    this.error = '';
        console.log('reason', event.addedFiles)
	    if (event.rejectedFiles[0]) {
	      if (event.rejectedFiles[0].reason==='type') {
	         this.error = 'fayl turlarini yuklashingiz mumkin: application/pdf'
	         return;
	      }
	      if (event.rejectedFiles[0].reason==='size') {
	         this.error = '10mb gacha fayl yuklashingiz mumkin';
	         return;
	      }
	    }
      if(this.files.length>0){
         this.error = 'Bitta pdf yuklash mumkin, Hamma fayllaringizni bitta pdf faylga joylashtirib jo‘nating';
         this.group.controls[this.field.type].setValue(this.files);
         return;
      }
	    this.files.push(...event.addedFiles);
  	    this.group.controls[this.field.type].setValue(this.files);

	}
	onRemove(event) {
	    this.files.splice(this.files.indexOf(event), 1);
  	    this.group.controls[this.field.type].setValue(this.files);
 	}
}
      // <div [formGroup]="group">
      //   <div *ngIf="!field.value" class="drop-container dropzone" dropZone (hovered)="toggleHover($event)"
      //     (dropped)="field.onUpload($event)" [class.hovering]="isHovering">
      //     <p class="m-0">
      //       Drag a file here or
      //       <label class="upload-button">
      //         <input type="file" multiple="" (change)="field.onUpload($event.target.files)"> browse
      //       </label>
      //       to upload.
      //     </p>
      //   </div>
      //   <div *ngIf="field.value">
      //     <!-- <button type="button" class="btn btn-primary">Change</button> -->
      //     <div class="card">
      //       <img class="card-img-top" [src]="field.value">
      //     </div>
      //   </div>
      // </div> 
      // .drop-container {
      //   background: #fff;
      //   border-radius: 6px;
      //   height: 150px;
      //   width: 100%;
      //   box-shadow: 1px 2px 20px hsla(0,0%,4%,.1);
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   border: 2px dashed #c0c4c7;
      // }
      // p {
      //   font-size: 16px;
      //   font-weight: 400;
      //   color: #c0c4c7; 
      // }
      // .upload-button {
      //   display: inline-block;
      //   border: none;
      //   outline: none;
      //   cursor: pointer;
      //   color: #5754a3;
      // }
      // .upload-button input {
      //   display: none;
      // }
      // .dropzone { 
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   flex-direction: column; 
      //   border-radius: 5px;
      //   background: white;
      //   margin: 10px 0;
      // }
      // .dropzone.hovering {
      //     border: 2px solid #f16624;
      //     color: #dadada !important;
      // }
      // progress::-webkit-progress-value {
      //   transition: width 0.1s ease;
      // }