<form class="modal-content" [formGroup]="form" (submit)="apply()">
  <div class="modal-header">
    <h4>Fakultet qo‘shish</h4>
  </div>
  <div class="modal-body">
    <div class="modal-body_item">
      <label>Fakultet nomi</label>
      <div class="modal-body_item_sec">
        <div class="red" *ngIf="errorFaculty">{{errorFaculty}}</div>
        <input type="text" class="form-control" (input)="checkForSimilarFaculty($event)" formControlName="name" />
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="close()">Bekor qilish</button>
    <button type="submit" class="btn btn-primary" [disabled]="form.invalid || errorFaculty">Tasdiqlash</button>
  </div>
</form>