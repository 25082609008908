import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BeingCheckedPvoRoutingModule } from './being-checked-pvo-routing.module';
import { BeingCheckedPvoComponent } from './being-checked-pvo.component';
import { SharedModule } from '../../shared/shared.module';
import { Ng2SmartTableModule } from 'ng2-smart-table';

@NgModule({
  declarations: [BeingCheckedPvoComponent],
  imports: [
    CommonModule,
    BeingCheckedPvoRoutingModule,
    SharedModule,
    Ng2SmartTableModule
  ]
})
export class BeingCheckedPvoModule { }
