import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { Router } from '@angular/router'

@Component({
  selector: 'button-view',
  template: `
    <div class="d-flex flex-nowrap text-white">
      <button type="button" class="btn btn-info" (click)="edit()">
        <i class="fa fa-arrow-right"></i>
      </button>
    </div>
  `,
})
export class ButtonViewComponent implements ViewCell, OnInit {

  @Input() value: string | number;
  @Input() rowData: any;

  constructor(private router: Router){}

  ngOnInit() {
  }

  // delete() {
  //   this.save.emit(this.rowData);
  // }
  edit() {
    this.router.navigateByUrl(this.rowData.link);
    // this.save.emit(this.rowData);
  }
}