<div class="container-fluid" *ngIf="!tmp.fieldLoad">
    <div class="row">
        <div class="col-sm-12">
			<div (click)="update1d5()" *ngIf="tmp.indexId==='gh6gd5'"><button class="btn btn-primary">1.5 indikatori ma'lumotlarini yangilash</button></div>
			<div class="card">
                <div *ngIf="field.status && field.status.title === 'closed';else open_index">
					<div *ngIf="tmp.indexId!=='gh6gd5'" class="card-header aling-center">
	                    <app-feather-icons [icon]="'alert-triangle'" class="alert-color big-svg mrr10"></app-feather-icons>
	                    <h5>
		                  <div *ngIf="!field.status.content;else contentExis">
							  Bu indikator vaqtinchalik admin tomonidan yopib qo‘yilgan!!!
		                  </div>
		                  <ng-template #contentExis>
		                  	{{field.status.content}}
		                  </ng-template>
	                    </h5>
	                </div>
				</div>

				<ng-template #open_index>
	                <div class="card-header">
	                    <h5>
	                      {{tmp.fieldTitle}}
	                    </h5>
						<h6 *ngIf="field.status&&field.status.explanation"><span style="color:#007bff">Indikator to‘liq ma‘nosi:</span> {{field.status.explanation}}</h6>
	                </div>
	                <div class="needs-validation card-body">
	                  <dynamic-form [fields]="fieldConfig" (submit)="submit($event)"></dynamic-form>
	                </div>
				</ng-template>
	        </div>
    	</div>
  	</div>
</div>  
<div class="lds-wrap" *ngIf="tmp.loadingFile.loadingFile">
	<progress-bar style="width: 50%;" [progress]="tmp.loadingFile.progressFileAll+tmp.loadingFile.progressFile" [color]="'#488aff'">
	</progress-bar>
	<!-- <circle-progress
	  [percent]="tmp.loadingFile.progressFileAll+tmp.loadingFile.progressFile"
	  [radius]="1.2"
	  [outerStrokeWidth]="0.35"
	  [innerStrokeWidth]="0.35"
	  [space] = "-0.35"
	  [outerStrokeColor]="'#007bff'"
	  [innerStrokeColor]="'#e7e8ea'"
	  [titleFontSize]= "0.5"
	  [unitsFontSize]= "0.5"
	  [showSubtitle] = "false"
	  [animation]="true"
	  [animationDuration]="300"
	  [startFromZero]="false"
	  [responsive]="true"
	></circle-progress> -->
</div>
<div class="lds-wrap" *ngIf="tmp.fieldLoad" (click)="test()">
	<div class="lds-dual-ring"></div>
</div>
