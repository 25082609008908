import { Injectable } from "@angular/core";
import { Socket } from 'ngx-socket-io'
@Injectable({
    providedIn: 'root'
})
export class WebSocketService {
    constructor(
      public socket: Socket,
    ){
        this.socket.connect();
        // console.log("ws connection established")
        // this.socket.fromEvent('update1d5').subscribe(data => {
        //   console.log('update1d5',data);
        // });
        // this.socket.fromEvent('queueNumber').subscribe(data => {
        //   console.log('queueNumber',data);
        // });
    }

}