<div class="container-fluid" *ngIf="!tmp.tableLoading">
<!--     <div class="card">
        <div class="card-body chard-fakul" >
            <div id="batchDelete" class="custom-datatable vendor-list">
                <div class="order-graph xl-space">
                    <h6>Fakultet grafikda</h6>
                    <div class="ct-4 flot-chart-container">
                        <x-chartist [type]="chartFakul.type" [data]="chartFakul.data" [options]="chartFakul.options">
                        </x-chartist>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="card" id="myBtn" #myBtn>
        <div class="card-body chard-fakul" >
            <div id="batchDelete" class="custom-datatable vendor-list">
                <div class="order-graph xl-space">
                    <h6>Kafedra grafikda</h6>
                    <div class="ct-4 flot-chart-container">
                        <x-chartist class="ov-x-auto" [type]="chartKaf.type" [data]="chartKaf.data" [options]="chartKaf.options">
                        </x-chartist>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card" *ngIf="showKbi" [@enterAnimation]>
        <div class="card-body chard-fakul" >
            <div id="batchDelete" class="custom-datatable vendor-list">
                <div class="order-graph xl-space">
                    <h6>{{tmp.kafName}}</h6>
                    <div class="ct-4 flot-chart-container">
                        <x-chartist class="ov-x-auto" [type]="chartKafInd.type" [data]="chartKafInd.data" [options]="chartKafInd.options">
                        </x-chartist>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!--     <div class="card">
        <div class="card-body vendor-table" >
            <div class="card-header">
                <h5>
                  Fakultet
                </h5>
            </div>
            <div id="batchDelete" class="custom-datatable vendor-list">
                    <div class="btn-popup pull-right">
                        <button type="button" class="btn btn-primary" (click)="exportexcel('excelTableFakultet')">Excelga ko'chirib olish</button>
                    </div>
                <div class="table-responsive">
                        <ng2-smart-table 
                            id="excelTableFakultet" 
                            [settings]="fakultetSet" 
                            [source]="fakultet"
                        ></ng2-smart-table>
                </div>
            </div>
        </div>
    </div> -->
    <div class="card">
        <div class="card-body vendor-table" >
            <div class="card-header">
                <h5>
                  Kafedra reytingi ro'yhatda
                </h5>
            </div>
            <div id="batchDelete" class="custom-datatable vendor-list">
                    <div class="btn-popup pull-right">
                        <button type="button" class="btn btn-primary" (click)="exportexcel('excelTableKafedra')">Excelga ko'chirib olish</button>
                    </div>
                <div class="table-responsive">
                        <ng2-smart-table 
                            id="excelTableKafedra" 
                            [settings]="kafedraSet" 
                            [source]="kafedra"
                        ></ng2-smart-table>
                </div>
            </div>
        </div>
    </div>
   <!--  <div class="card">
        <div class="card-body vendor-table" >
            <div class="card-header">
                <h5>
                  O'qituvchilar
                </h5>
            </div>
            <div id="batchDelete" class="custom-datatable vendor-list">
                    <div class="btn-popup pull-right">
                        <button type="button" class="btn btn-primary" (click)="exportexcel('excelTablePvo')">Excelga ko'chirib olish</button>
                    </div>
                <div class="table-responsive">
                        <ng2-smart-table 
                            id="excelTablePvo" 
                            [settings]="settings" 
                            [source]="source"
                        ></ng2-smart-table>
                </div>
            </div>
        </div>
    </div> -->
</div>
<div class="lds-wrap" *ngIf="tmp.tableLoading">
    <div class="lds-dual-ring"></div>
</div>