import { Component, OnInit } from '@angular/core';
import {
  trigger,
  transition,
  useAnimation
} from '@angular/animations';

import { slideFadeOut, useSlideFadeInAnimation } from 'src/app/shared/animations/animations';
import { fadeInAndOut } from 'src/app/shared/animations/triggers';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { SimpleModalService } from 'ngx-simple-modal';
import { AddAreaModalComponent } from './modal/add-area.component';
import { RenderEditRowComponent } from './render_components/render-edit-row.component';
import { EditRowModalComponent } from './modal/edit-row-modal.component';
import { ConfirmComponent } from 'src/app/shared/components/modal/confirm/confirm.component'
// import { ApolloService } from 'src/app/shared/services/apollo.service';

import { DataService } from 'src/app/shared/service/data.service';
import { HttpService } from 'src/app/shared/service/http.service';

interface group {
  name: string;
  subsCurr: string;
  groupCap: number;
}
@Component({
  selector: 'app-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.scss'],
  animations: [
    // The following are pre-built triggers - Use these to add animations with the least work
    fadeInAndOut,

    // The following is a custom trigger using animations from the package
    // Use this approach if you need to customize the animation or use your own states
    trigger('enterFromLeftLeaveToRight', [
      // this transition uses a function that returns an animation with custom parameters
      transition(':enter', useSlideFadeInAnimation('300ms', '20px')),
      // This transition uses useAnimation and passes the parameters directly - accomplishing the same thing as the above function
      transition(':leave', useAnimation(slideFadeOut, {params: {time: '2000ms', endPos: '100px'}})),
    ]),
  ],
})
export class AreaComponent implements OnInit {

  dataSource: LocalDataSource = new LocalDataSource();
  loading:boolean=true;
  // area = this.dataService.areas;

  public settings = {
    pager: {
      display: true
    },
    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'right'
    },
    columns: {
      departmentName: {
        title: "Fakultet",
      },
      name: {
        title: "Yo‘nalish",
      },
      numOfEmployees: {
        title: "Xodimlar soni",
        // valuePrepareFunction: (cell:any, row:any) => row.numOfEmployees
      },
      edit: {
        title: 'Harakat',
        type: 'custom',
        renderComponent: RenderEditRowComponent,
        onComponentInitFunction: (instance:any) => {
          instance.save.subscribe((res:any) => {
            if(res.type === 'edit'){
              this.editRowModal(res.rowData);
            }else{
              this.trashRowModal(res.rowData)
            }
          });
        }
      },
    }
  };
  constructor(
    public dataService: DataService,
    public httpService: HttpService,
    // private apollo: ApolloService,
    private SimpleModalService: SimpleModalService,
    ){

  }
  async trashRowModal(rowData:any){
    const message = await this.SimpleModalService.addModal(ConfirmComponent, {
      title: 'O‘chirish',
      message: 'Qatorni aniq o‘chirmoqchimisiz?'
      }).toPromise()
        if(message){
            try{
              const result = await this.httpService.removeArea(rowData.id)
              this.dataSource['data'] = this.dataSource['data'].filter(a=>a.id!==rowData.id);
              this.dataSource.refresh();
            }catch(error){
              this.showError(error);
            }    
        }
  }
  async editRowModal(rowData:any){
    const message:any = await this.SimpleModalService.addModal(EditRowModalComponent, { title: 'Baholash', question: JSON.stringify(rowData)}).toPromise()
    if(message){
      const m = JSON.parse(message);
      console.log(m)
        try{
          await this.httpService.editArea(m)
          this.dataSource['data'] = this.dataSource['data'].map(a=>{
            if(a.id===m.id){return m;}else{return a;}
          });
          this.dataSource.refresh();
        }catch(error){
          this.showError(error);
        }
    }
  }
    //areas ikkinchi qadam

  async addItem(){
    const message:any = await this.SimpleModalService.addModal(AddAreaModalComponent, { title: 'Baholash', question: 'Ulushni kirgizing'}).toPromise()
    const m = JSON.parse(message);
    if(m){
        this.loading = true;
        try{
          const result:any = await this.httpService.addArea(m)
          this.dataSource['data'].unshift({...m})
          this.dataSource.refresh();
          this.loading = false;
        }catch(error){
          this.showError(error);
        }
    }
  }  
  showError(e:any){
    alert('Nimadur hato ketti yana bir bor urinib ko\'ring! Error: '+e.error.message);
    // this.dataService.loading = false;
    // this.dataService.finishLoading();
  }
  ngOnInit() {

    console.log(this.dataService.areas)
    this.dataSource['data'] = this.dataService.areas;
  } 

  onDeleteConfirm(event:any){
    console.log('onDeleteConfirm', event)
  }
  onEditConfirm(event:any){
    console.log('onEditConfirm', event)
  }

  openCreateDialog(event:any){

  }

}
