import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../../shared/service/data.service';
import { HttpService } from '../../../shared/service/http.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-create-teacher',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateTeacherComponent implements OnInit {
  public accountForm: FormGroup;
  public permissionForm: FormGroup;
  tizim;
  tmp = {error: "", loading: false};
  pvoKaf;
  otm;
  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private httpService: HttpService,
  ) {
    this.createAccountForm();
    this.createPermissionForm();
    this.tizim = dataService.tizim;
    dataService.pvoKaf$.subscribe(res=>{
      this.pvoKaf = res;
    })
  }

  createAccountForm() {
    //ferpi
    // this.accountForm = this.formBuilder.group({
    //   fname: ['', Validators.required],
    //   lname: ['', Validators.required],
    //   patronymic: [''],
    //   born: ['', Validators.required],
    //   rank: ['', Validators.required],
    //   phone: [''],
    //   wagerate: ['', Validators.required],
    //   academic_degree: [''],
    //   gender: ['', Validators.required],
    //   code_specialty: [''],
    //   year_of_employment: [''],
    //   year_started_teaching: [''],
    //   masters_spec: [''],
    //   google_link: [''],
    //   academic_title: [''],
    //   state_type: ['', Validators.required],
    //   kafed: ['', Validators.required],
    //   fakul: ['', Validators.required],
    //   email: ['', [Validators.required, Validators.email]],
    //   password: ['', [Validators.required, Validators.minLength(6)]],
    // })
    this.accountForm = this.formBuilder.group({
      fname: ['', Validators.required],
      lname: ['', Validators.required],
      patronymic: [''],
      kafed: ['', Validators.required],
      fakul: ['', Validators.required],
    })
  }
  tanKafedra(){
    return (this.tizim.filter(res => res.fakultet === this.accountForm.value.fakul))[0];
  }
  createPermissionForm() {
    this.permissionForm = this.formBuilder.group({
    })
  }
  ngOnInit() {
    this.otm = environment.otm;
  }
  get f(){
    return this.accountForm.controls;
  }
  createLoginPass(){
    const {fname,lname, patronymic, email, password} = this.accountForm.value;
    this.httpService.createUser({first_name:fname, last_name:lname, patronymic, email, password}).then(async res => {
        this.accountForm.reset();
        this.tmp.loading = false;
        alert('Ma\'lumotlar muvaffaqiyatli saqlandi!')
        this.tmp.error = ''
    }, async err => {
        this.tmp.loading = false;
        alert(err.message);
    });
  }
  onAdd(event){
    var value = this.accountForm.value
    this.tmp.loading = true;
    if (this.pvoKaf.find(res=> ((res.fname)?.replace(/[ ,.]/g, "") === (value.fname)?.replace(/[ ,.]/g, "")) && ((res.lname)?.replace(/[ ,.]/g, "") === (value.lname)?.replace(/[ ,.]/g, "")) && ((res.patronymic?res.patronymic:'').replace(/[ ,.]/g, "") === (value.patronymic?value.patronymic:'').replace(/[ ,.]/g, "")))) {
      this.tmp.loading = false;
      return this.tmp.error="Bunday ism va sharifli o'qituvchi mavjud!";
    }
    this.httpService.setNewPvo(value)
    .subscribe((result:any)=>{
      if (result.status === 200) {
        this.accountForm.reset();
        this.tmp.loading = false;
        alert('Ma\'lumotlar muvaffaqiyatli saqlandi!')
        this.tmp.error = ''
      }else{
        alert('Nimadur hato ketti!')
      }
    }, error =>{
      console.error(error);
    })
  }
}
