import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router'
import { NavService } from '../../service/nav.service';
import { DataService } from '../../service/data.service';
import { HttpService } from '../../service/http.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounce, zoomOut, zoomIn, fadeIn, bounceIn } from 'ng-animate';
import { Validators } from "@angular/forms";

interface IDepartmentAArea{
  departments: IDepartment[]
}
interface IDepartment{
  name:string
}
@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
  animations: [
    trigger('animateRoute', [transition('* => *', useAnimation(fadeIn, {
      // Set the duration to 5seconds and delay to 2 seconds
      //params: { timing: 3}
    }))])
  ]
})
export class ContentLayoutComponent implements OnInit {

  public right_side_bar: boolean;
  public layoutType: string = 'RTL';
  public layoutClass: boolean = false;
  loading: boolean = true;

  constructor(
    public navServices: NavService,
    private dataService: DataService,
    private httpService: HttpService,
    private route: ActivatedRoute,
    private router: Router
    ) { 
      // router.events.subscribe(event=>{
      //   if (event instanceof NavigationEnd) {
      //     if (event.url.indexOf("/indexes/") === -1) {
      //       this.loading = false;
      //     }else{
      //     }
      //   }
      // })
      // this.getIndexForms();
      this.getFields();
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  public rightSidebar($event) {
    this.right_side_bar = $event
  }

  public clickRtl(val) {
    if (val === 'RTL') {
      document.body.className = 'rtl';
      this.layoutClass = true;
      this.layoutType = 'LTR';
    } else {
      document.body.className = '';
      this.layoutClass = false;
      this.layoutType = 'RTL';
    }
  }
  getPvo(i){
      // console.log(i)
      this.httpService.getDoc(["pvoIns"]).subscribe(data => {
        var arr = [];
        (Object.keys(data)).forEach((res, ss, sss)=>{
          arr.push(data[res]);
          if (sss.length === (ss+1)) {
            localStorage.setItem('pvoIns', JSON.stringify(arr))
            this.dataService.pvoKaf$.next(arr);
          }
        })
      }, error => {
        if (i<10) {
          setTimeout(()=>{
            this.getPvo(i+1);
          }, 2000);
        }else{
          alert(JSON.stringify(error));
          window.location.reload();
        }
      });
  }
  async getFields(){
    try{
      const {departments}:any = await this.httpService.getDepartmentAArea();
      this.dataService.departments = departments;
      departments?.map(r=>{r.areas?.map(a=>{this.dataService.areas.push({...a, departmentName:r.name})})})
      var oldDate = JSON.parse(localStorage.getItem('fieldTime'))
      var newDate = new Date();
      if (newDate.getDate() === oldDate) {
        var obj = JSON.parse(localStorage.getItem('fieldData'));
        this.addValidators(obj);
        this.getPvo(0);
      }else{
        this.getFieldsFromDb(0);
      }
    }catch(e){
      console.log(e)
    }

  }
  async getFieldsFromDb(i){
      // console.log(i)
    await  this.httpService.getDoc(["fieldData"]).subscribe(data => {
      if (data) {
        // console.log('data 3: ', data)
        this.addValidators(data);
        this.getPvo(0);
        var newDate = new Date();
        localStorage.setItem('fieldData', JSON.stringify(data));
        localStorage.setItem('fieldTime', newDate.getDate().toString());
      }else{
        this.loading = false;
        alert('empty forms');
      }
    },error=>{
      if (i<10) {
        // console.log('fields ', i)
        setTimeout(()=>{
          this.getFieldsFromDb(i+1);
        }, 2000);
      }else{
        alert('#456' + JSON.stringify(error));
        window.location.reload();
      }
    });
  }
  async addValidators(fields){
    var arr = [];
    await Object.keys(fields).forEach((key, idxK, sssK)=>{
        arr.push(fields[key])
        if (sssK.length === (idxK+1)) {
          arr.forEach(config=>{
            if (config) {
              config.config.forEach(field=>{
                  field.validations?.forEach((validation, res, data)=>{
                    switch (validation.type) {
                      case "required":
                        data[res].validator = Validators.required;
                        break;
                      case "text":
                        data[res].validator = Validators.pattern("^[a-zA-Z]+$");
                        break;
                      case "email":
                        data[res].validator = Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$")
                        break;
                      case "requiredTrue":
                        data[res].validator = Validators.requiredTrue;
                        break;

                      // default:
                      //   // code...
                      //   break;
                    }
                  })
              })
            }
          })
        }
    })
    await this.dataService.field$.next(arr);
    this.loading = false;
  }
  // getIndexForms(){
  //   var oldDate = JSON.parse(localStorage.getItem('indexFormsDataTime'))
  //   var newDate = new Date();
  //   if (newDate.getDate() === oldDate) {
  //     var obj = JSON.parse(localStorage.getItem('indexFormsData'))
  //     this.loading = false;
  //     this.indexFormsService.forms.next(obj);
  //   }else{
  //     this.getIndexFormsFromDb(0);
  //   }
  // }
  // async getIndexFormsFromDb(i){
  //   var newDate = new Date();
  //   await this.indexFormsService.getformsS(0).then(snapshot=>{
  //     if (data) {
  //       this.loading = false;
  //       this.indexFormsService.forms.next(snapshot.val());
  //       localStorage.setItem('indexFormsData', JSON.stringify(snapshot.val()))
  //       localStorage.setItem('indexFormsDataTime', newDate.getDate().toString());
  //     }else{
  //       alert('empty forms');
  //     }
  //   }).catch(error=>{
  //     if (i<10) {
  //       setTimeout(()=>{
  //         this.getIndexFormsFromDb(i+1);
  //       }, 1000);
  //     }else{
  //       alert(error);
  //       window.location.reload();
  //     }
  //   });
  // }
  ObjectEqual(obj1, obj2){
    if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
    }else{
    }
//     Object.equals = function( x, y ) {
//     if ( x === y ) return true;
//     // if both x and y are null or undefined and exactly the same

//     if ( ! ( x instanceof Object ) || ! ( y instanceof Object ) ) return false;
//     // if they are not strictly equal, they both need to be Objects

//     if ( x.constructor !== y.constructor ) return false;
//     // they must have the exact same prototype chain, the closest we can do is
//     // test there constructor.

//     for ( var p in x ) {
//         if ( ! x.hasOwnProperty( p ) ) continue;
//         // other properties were tested using x.constructor === y.constructor

//        if ( ! y.hasOwnProperty( p ) ) return false;
//        // allows to compare x[ p ] and y[ p ] when set to undefined

//        if ( x[ p ] === y[ p ] ) continue;
//        // if they have the same strict value or identity then they are equal

//        if ( typeof( x[ p ] ) !== "object" ) return false;
//        // Numbers, Strings, Functions, Booleans must be strictly equal

//        if ( ! Object.equals( x[ p ],  y[ p ] ) ) return false;
//        // Objects and Arrays must be tested recursively
//    }

//    for ( p in y ) {
//       if ( y.hasOwnProperty( p ) && ! x.hasOwnProperty( p ) ) return false;
//       // allows x[ p ] to be set to undefined
//     }
//     return true;
// }
  }
  ngOnInit() { }

}
