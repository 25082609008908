import { Component, ViewChild } from "@angular/core";
import { SimpleModalComponent } from "ngx-simple-modal";
import { DataService } from "src/app/shared/service/data.service";
// import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, interval } from 'rxjs';
import { WebSocketService } from 'src/app/shared/service/socket.service'
import { LocalDataSource } from "ng2-smart-table";
import { map, take } from "rxjs/operators";
// import jsdom from 'jsdom'
// const { JSDOM } = jsdom;
// import axios from 'axios';
export interface PromptModel {
    title:string;
    question:string;
}

@Component({
    selector: 'update1d5',
    templateUrl: './update1d5.component.html',
    styleUrls: ['./update1d5.component.scss']
})
export class Update1d5Component extends SimpleModalComponent<PromptModel, string> implements PromptModel{
    timer$: Observable<number>;
    title: string = '';
    question: string = '';
    message: string = '';
    keyword = 'name';
    timer;
    @ViewChild('teacher_yu') teacher_yu:any;
    error;queue;
    dataSource: LocalDataSource = new LocalDataSource();
    public settings = {
        pager: {
          display: true
        },
        actions: {
          add: false,
          edit: false,
          delete: false,
          position: 'right'
        },
        columns: {
          author: {
            title: 'Muallif F.I.Sh'
          },
          journal_name: {
            title: 'Jurnalning nomi'
          },
          published_date: {
            title: "Jurnalning nashr etilgan yili va oyi",
          },
          article_name: {
            title: "Maqolaning nomi"
          },
          link : {
            title: "Chop etilgan materiallarning «Google Scholar» va boshqa xalqaro e'tirof etilgan qidiruv tizimlardagi internet manzili (giper xavolasi)"
          },
          citiations: {
            title: "Iqtiboslar soni"
          },
          grade: {
            title: "Berilgan ulush"
          },
        }
      };
    timeTableTeachers:any[] = [];
    calendarVisible = false;
    constructor(
        private dataService: DataService,
        private socketService: WebSocketService
      ) {
        super();
        dataService.pvoKaf$.subscribe(async(res)=>{
            this.generateTT(res)
        })
        this.socketEvents();
    }
    socketEvents(){
        this.socketService.socket.fromEvent('updated1d5').subscribe(data => {
            this.dataSource['data'].unshift({
                      author: data[0].value,
                      journal_name: data[1].value,
                      published_date: data[2].value,
                      article_name: data[3].value,
                      link : data[5].value,
                      citiations: data[6].value,
                      grade: data[7].grade,
            });
            this.dataSource.refresh();
        });
        this.socketService.socket.fromEvent('queueNumber').subscribe(data =>{
            this.queue=data
        });
        this.socketService.socket.fromEvent('error').subscribe(data =>this.error=data);
        this.socketService.socket.fromEvent('updateTimer').subscribe((time:number) =>{
          this.timer$ = interval(1000).pipe(
            take(time/1000),
            map(elapsedSeconds => time/1000 - elapsedSeconds)
          )
        });
    }
    generateTT(teachers:any){
        this.timeTableTeachers = teachers.map(t=>{return {...t, name: t.lname + ' ' + t.fname + ' ' + t.patronymic}})
    }
    teacherClearedEvent(event){
    }
    send(msg){
        this.socketService.socket.emit("update1d5", msg)
    }
    async selectTeacherEvent(event){
        this.error='';
        const {added_id, ...props} = event
        this.send({added_id})
        // await this.getAllArticleUrlFromProfile("https://scholar.google.com/citations?user=kEapb18AAAAJ&hl=ru")
    }
    // async getAllArticleUrlFromProfile(teacherUrl:string){
    //     return new Promise(async (resolve)=>{
    //       try{
    //         const url = new URL(teacherUrl) ;
    //         // const params = {
    //         //   user: url.searchParams.get('user'),
    //         //   hl: 'en',
    //         //   cstart: 1,
    //         //   pagesize: 200,
    //         // }
    //         const params = new HttpParams()
    //         .set('user', url.searchParams.get('user'))
    //         .set('hl', 'en')
    //         .set('cstart', '1')
    //         .set('pagesize', '200');
    //         const res = await this.http.get("https://scholar.google.com/citations?user=kEapb18AAAAJ&hl=ru").toPromise();
    //         console.log(res)
    //         // const dom = new JSDOM(res.data); let article_url = []; let counter = 1; const domwindoc = dom.window.document;
    //         // while(domwindoc.querySelector(`#gsc_a_b > tr:nth-child(${counter}) > td.gsc_a_c`) && parseInt(domwindoc.querySelector(`#gsc_a_b > tr:nth-child(${counter}) > td.gsc_a_c`).textContent)){
    //         //   article_url.push(domwindoc.querySelector(`#gsc_a_b > tr:nth-child(${counter}) > td.gsc_a_t > a`).attributes[0].value);
    //         //   counter++;
    //         // }
    //         // resolve(article_url);
    //       }catch(e){console.log('error getAllArticleUrlFromProfile', e.message); if(e.response&&e.response.status&&e.response.status==429){resolve({status:e.response.status})}else{resolve(null)} }
    //     })
    //   }
}