import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { CreateIndexRoutingModule } from './create-index-routing.module';
import { CreateIndexComponent } from './create-index.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DynamicFormModule } from "../../shared/dynamic-form/dynamic-form.module";
import { SharedModule } from '../../shared/shared.module';
@NgModule({
  declarations: [
    CreateIndexComponent
  ],
  imports: [
    CreateIndexRoutingModule,
    DynamicFormModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    SharedModule
  ],
  providers: [],
  bootstrap: [
  ],
  entryComponents: [
  ]

})
export class CreateIndexModule { }
