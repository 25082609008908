  
  <div class='demo-app'>
   <div class='demo-app-main'>
    <div><button (click)="addItem()" class="btn btn-primary">Yo‘nalish qo‘shish</button></div>
    <ng2-smart-table 
      id="excelTable" 
      class="ng2-smart-table" 
      [settings]="settings" 
      [source]="dataSource"
      (deleteConfirm)="onDeleteConfirm($event)"
      (editConfirm)="onEditConfirm($event)"
      (create)="openCreateDialog($event)"
    ></ng2-smart-table>
    </div>
  </div>

  <!-- <div *ngIf="dataService.loading">
    <div id="box" ><svg><filter id="glowfloxs"><feGaussianBlur in="SourceGraphic" stdDeviation="10" /><feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 20 -10"></feColorMatrix></filter></svg><div class="loader"><span style="--i:1;"></span><span style="--i:2;"></span><span style="--i:3;"></span><span style="--i:4;"></span><span style="--i:5;"></span><span style="--i:6;"></span><span style="--i:7;"></span><span style="--i:8;"></span><span style="--i:9;"></span><span style="--i:10;"></span><span class="rotate" style="--watet:0;"></span><span class="rotate" style="--watet:2;"></span><span class="rotate" style="--watet:3;"></span><span class="rotate" style="--watet:4;"></span><span class="rotate" style="--watet:5;"></span><span class="rotate" style="--watet:6;"></span><span class="rotate" style="--watet:7;"></span></div></div>
  </div> -->