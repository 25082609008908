import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { Router } from '@angular/router'
import { DataService } from 'src/app/shared/service/data.service';

@Component({
  selector: 'list-button-view',
  template: `
    <div class="d-flex flex-nowrap text-white center" *ngIf="dataService.hasRole('SUPERADMIN')||dataService.hasRole('ADMIN')">
          <button  type="button" title="Qatorni o‘zgartirish" class="btn btn-info" (click)="edit()">
            <i class="fa fa-edit"></i>
          </button>
          <button  type="button" title="Qatorni o‘zgartirish" class="btn btn-info left" (click)="trash()">
            <i class="fa fa-trash"></i>
          </button>
    </div>
  `,
  styles: [`
    .center{
      justify-content:center;
    }
    .pending{
      color: #000000;
      font-size: 20px;
    }
    .green{
      color: #3ceb3c;
      font-size: 20px;
    }
    .left{
      margin-left: 10px;
    }
    .btn{
      padding: 3px 0 0 0px;
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }
  `]
})
export class RenderEditRowComponent implements ViewCell, OnInit {

  @Input() value: string = '';
  @Input() rowData: any;
  // @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();
  constructor(
    private router: Router,
    public dataService: DataService
){}

  ngOnInit() {
  }

  edit() {
    this.save.emit({type: 'edit', rowData:this.rowData});
  }
  trash() {
    this.save.emit({type: 'trash', rowData:this.rowData});
  }
}
