import { Component, ViewChild, OnInit } from "@angular/core";
import { FieldConfig, FieldData } from "../../shared/interface/field.interface";
import { DynamicFormComponent } from "../../shared/dynamic-form/dynamic-form.component";
import { HttpService } from 'src/app/shared/service/http.service'
import { FormGroup, FormControl, Validators, FormArray, FormBuilder, ValidatorFn, AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-create-index',
  templateUrl: './create-index.component.html',
  styleUrls: ['./create-index.component.scss']
})
export class CreateIndexComponent implements OnInit {
  formGenField: FormGroup;
  fieldItems: FormArray;
  list : any[];
  tmp = {
    validations: [],
    tableLoading: false,
    rowCount: 0,
    inputSev: '',
    loading: false
  }
  @ViewChild(DynamicFormComponent) form: DynamicFormComponent;
  constructor(
      private httpService:HttpService,
      private formBuilder: FormBuilder
    ){
    // this.firebaseService.setDoc('fieldData/0', this.fieldData[0])
    // .then(res=>{
    //   alert('success!');
    // }).catch(error=>{
    // })
    this.list = 
      [
        {name :'Qator to\'ldirilishi shart',checked : false},
        {name :'Faqat harf kirgizadi',checked : false},
        {name :'Faqat raqam kirgizadi',checked : false},
        {name :'Email kirgizadi!',checked : false},
        {name :'Parol kirgizadi!',checked : false},
      ]
  }
  ngOnInit(){
      this.formGenField = new FormGroup({
        title: new FormControl('', Validators.required),
        id: new FormControl('', this.checkFieldId),
        coeff: new FormControl(''),
        input: new FormControl(''),
        fieldItems: new FormArray([])
      });
  }
  get f(){
    return this.formGenField.controls;
  }
  checkFieldId(control: AbstractControl): ValidationErrors {
    if (control.parent != undefined) {
      var id: string = control.parent.get("id").value;
      if (!id) {
        return {id: "id ni kirgizing!"};
      }else{
        var fieldData = JSON.parse(localStorage.getItem('fieldData'))
        if (fieldData) {
          var exists: boolean = false;
          Object.keys(fieldData).forEach(fer=>{
            if (fer) {
              if (fieldData[fer].id === replaceAll(id, ".", "d")) {
                exists = true;
              }
            }
          })
          // fieldData.forEach((res)=>{
          //   if (res) {
          //     if (res.id === replaceAll(id, ".", "d")) {
          //       exists = true;
          //     }
          //   }
          // })
          if (exists) {
            return {id: 'bunday id mavjud'}
          }else{
            return null;
          }
        }else{
          return null;
        }
      }
    }
    return {id: true};
  }
  async saveCreTabDb(){
    this.tmp.loading = true;
    var fieldData = await JSON.parse(localStorage.getItem('fieldData'));
    console.log('fieldData', fieldData);
    (Object.keys(fieldData)).forEach((res, idx, sss)=>{
      if (sss.length === (idx+1)) {
        this.httpService.createIndex(((parseInt(res)+1).toString()), this.fieldData)
        .subscribe((res:any)=>{
          if(res.status === 200){
            localStorage.removeItem("fieldData")
            localStorage.removeItem("fieldTime");
            alert('Ma\'lumotlar muvaffaqiyatli saqlandi!');
            window.location.reload();
          }else{
            alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');  
          }
        console.log('result ', res)
        },error=>{
          alert('Nimadur hato ketti yana bir bor urinib ko\'ring! Error: ' + JSON.stringify(error));
        });

        // this.firebaseService.setDoc('fieldData/'+((parseInt(res)+1).toString()), this.fieldData)
        // .then(res=>{
        //     localStorage.removeItem("fieldData")
        //     localStorage.removeItem("fieldTime");
        //     alert('Ma\'lumotlar muvaffaqiyatli saqlandi!');
        //     window.location.reload();
        // }).catch(error=>{
        //   alert('#932 ' + error)
        // })
      }
    });

  }
  test(){

  }
  createItem(value): FormGroup {
    var arr = {
      input: {
          label: '',
          fileSave: ''
      },
      inputautocomplete: {
          label: '',
          fileSave: '',
          pvoNames: ''
      },
      date: {
          label: '',
        },
      file:{
          label: '',
      },
      number:{
          label: '',
      },
      select: {
          label: '',
          name: ''
      },
      selectOpt: {
          name: ''
      },
      checkbox:{
          label: '',
      },
      radiobutton: {
          label: '',
          name: ''
      },
      button: {
          type: 'button',
          label: 'Saqlash'
      }
    }
    return this.formBuilder.group(arr[`${value}`]);
                           // text
                           // radiobutton
                           // date
                           // select
                           // checkbox
                           // file
                           // button
                           // number
  }
  shareCheckedList(items:any[]){
    var arr = [];
    items.forEach(res=>{
      switch (res) {
        case "Qator to\'ldirilishi shart":
          arr.push({name: "required", message: "Qator to\'ldirilishi shart", type: "required"});
          break;
        case "Faqat harf kirgizadi":
          arr.push({name: "pattern", message: "Faqat harf kirgizing", type: "text"});
          break;
        case "Faqat raqam kirgizadi":
          arr.push({name: "pattern", message: "Faqat raqam kirgizing", type: "number"});
          break;
        case "Email kirgizadi!":
          arr.push({name: "pattern", message: "Email kirgizing!", type: "email"});
          break;
        case "Parol kirgizadi!":
          arr.push({name: "required", message: "Parol kirgizing!", type: "password"});
          break;
        default:
          
          break;
      }
    })
    this.tmp.validations = arr;
  }
  // shareIndividualCheckedList(item:{}){
  // }
  addInput(value){
    this.tmp.tableLoading = false;
    // this.formGenField.controls['fieldItems'].setValue([{}]);
    this.fieldItems = this.formGenField.get('fieldItems') as FormArray;
    if (value === 'select' || value === 'radiobutton') {
      this.tmp.inputSev = 'select';
      
    }else{
      this.tmp.inputSev = 'first';
      if (this.fieldItems.controls[0]) {
        this.fieldItems.removeAt(0);
      }
    }
    // if (value!=='file') {
      this.fieldItems.push(this.createItem(value));
    // }
  }
  addField(value){
    this.fieldItems = this.formGenField.get('fieldItems') as FormArray;
    this.fieldItems.push(this.createItem(value));
  }
  initialize(){
      // this.formGenField = new FormGroup({
      //   title: new FormControl('ss', Validators.required),
      //   id: new FormControl('sss', Validators.required),
      //   input: new FormControl(''),
      //   fieldItems: new FormArray([])
      // });
      this.tmp = {
        validations: [],
        tableLoading: false,
        rowCount: this.tmp.rowCount,
        inputSev: '',
        loading: false
      }
      for(var i=this.formGenField.controls['fieldItems'].value.length-1; i>=0; i--){
        this.fieldItems.removeAt(i);
      }
      this.formGenField.controls['fieldItems'].reset();
      this.formGenField.controls['input'].setValue('');
      this.list.forEach((s, ss, sss)=>{
        sss[ss].checked = false;
      })
  }
  onSubmit(event){
    var value = this.formGenField.value;
    var fieldValue = this.formGenField.value.fieldItems[0];
    this.fieldData.title = value.title;
    this.fieldData.id = replaceAll(value.id, ".", "d");
    this.fieldData.coeff = parseFloat(value.coeff);
    let arr: FieldConfig = {
      type: value.input,
      label: (value.input === 'file')?'file':fieldValue.label,
      name: (value.input === 'file')?'file':this.rowCount(),
      validations: this.tmp.validations
    }
    if (value.input === "input" || value.input === "inputautocomplete") {
      if (value.input === "input") {
        arr["inputType"] = "text";
      }
      if (fieldValue.fileSave) {
        arr["fileSave"] = true;
      }
      if (fieldValue.pvoNames) {
        arr["pvoNames"] = true;
      }
    }
    this.tmp.validations.forEach(res=>{
      switch (res.type) {
        case "email":
          arr["inputType"] = "email";
          break;
        case "password":
          arr["inputType"] = "password";
          break;
        
        default:
          // code...
          break;
      }
    })
    if (this.tmp.inputSev === 'select') {
      var s = [];
      value.fieldItems.forEach(res=>{
        s.push(res.name);
      })
      arr['options'] = s;
      arr['value'] = '';
    }
    this.regConfig.push(arr)
    this.initialize();
    this.tmp.tableLoading = true;
  }
  rowCount(){
    this.tmp.rowCount++;
    return 'title'+this.tmp.rowCount;
  }
  submit(event){
  }
  regConfig: FieldConfig[] = [
    // {
    //   type: "input",
    //   label: "Асосий штатдаги профессор-ўқитувчиларнинг Ф.И.Ш (алфавит тартибида тўлиқ ёзилади)",
    //   inputType: "text",
    //   name: "name",
    //   validations: [
    //     {
    //       name: "required",
    //       // validator: Validators.required,
    //       message: "Name Required",
    //       type: 'required'
    //     },
    //     {
    //       name: "pattern",
    //       // validator: Validators.pattern("^[a-zA-Z]+$"),
    //       message: "Accept only text",
    //       type: 'text'
    //     }
    //   ]
    // },
    // {
    //   type: "input",
    //   label: "Email Address",
    //   inputType: "email",
    //   name: "email",
    //   validations: [
    //     {
    //       name: "required",
    //       // validator: Validators.required,
    //       message: "Email Required",
    //       type: 'required'
    //     },
    //     {
    //       name: "pattern",
    //       // validator: Validators.pattern(
    //       //   "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"
    //       // ),
    //       message: "Invalid email",
    //       type: 'email'
    //     }
    //   ]
    // },
    // {
    //   type: "input",
    //   label: "Password",
    //   inputType: "password",
    //   name: "password",
    //   validations: [
    //     {
    //       name: "required",
    //       // validator: Validators.required,
    //       message: "Password Required",
    //       type: "password"
    //     }
    //   ]
    // },
    // {
    //   type: "radiobutton",
    //   label: "Gender",
    //   name: "gender",
    //   options: ["Male", "Female"],
    //   value: "Male"
    // },
    // {
    //   type: "date",
    //   label: "DOB",
    //   name: "dob",
    //   validations: [
    //     {
    //       name: "required",
    //       // validator: Validators.required,
    //       message: "Date of Birth Required",
    //       type: 'required'
    //     }
    //   ]
    // },
    // {
    //   type: "select",
    //   label: "Country",
    //   name: "country",
    //   value: "",
    //   options: ["India", "UAE", "UK", "US"],
    //   validations: [
    //     {
    //       name: "required",
    //       // validator: Validators.required,
    //       message: "country Required",
    //       type: 'required'
    //     }
    //   ]
    // },
    // {
    //   type: "checkbox",
    //   label: "Accept Terms",
    //   name: "term",
    //   value: false,
    //   validations: [
    //     {
    //       name: "required",
    //       // validator: Validators.requiredTrue,
    //       message: "Terms",
    //       type: 'requiredTrue'
    //     }
    //   ]
    // },
    // {
    //   type: "file",
    //   label: "file",
    //   name: "file",
    //   // onUpload: this.onUpload.bind(this)
    // },
    // {
    //   type: "number",
    //   label: "number",
    //   name: "number",
    //   value: 1
    // },
    // {
    //   type: "button",
    //   label: "Save"
    // }
  ];
  // regConfig: FieldConfig[] = [
  //   {
  //     type: "date",
  //     label: "DOB",
  //     name: "dob",
  //     validations: [
  //       {
  //         name: "required",
  //         // validator: Validators.required,
  //         message: "Date of Birth Required"
  //       }
  //     ]
  //   },
  //   {
  //     type: "select",
  //     label: "Country",
  //     name: "country",
  //     value: "",
  //     options: ["India", "UAE", "UK", "US"],
  //     validations: [
  //       {
  //         name: "required",
  //         // validator: Validators.required,
  //         message: "country Required"
  //       }
  //     ]
  //   },
  //   {
  //     type: "checkbox",
  //     label: "Accept Terms",
  //     name: "term",
  //     value: false,
  //     validations: [
  //       {
  //         name: "required",
  //         // validator: Validators.requiredTrue,
  //         message: "Terms",
  //         type: "requiredTrue"
  //       }
  //     ]
  //   },
  //   {
  //     type: "file",
  //     label: "file",
  //     name: "file",
  //     // onUpload: this.onUpload.bind(this)
  //   },
  //   {
  //     type: "number",
  //     label: "number",
  //     name: "number",
  //     value: 1
  //   },
  //   {
  //     type: "button",
  //     label: "Save"
  //   }
  // ];
  fieldData: FieldData = 
    {
      id: '',
      config: this.regConfig,
      title: '',
      coeff: 1
    }
  // Фарғона Политехника Институтида Дунёнинг Нуфузли 1000 Талигига Кирувчи Олий Таълим Муассасаларида PhD (Фалсафа Доктори Ёки Фан Номзоди) Ёки DSc (Фан Доктори) Каби Илмий Даражаларни Олган Профессор-Ўқитувчилар Улуши Ҳақидаги

  // onUpload(e) {

  // }
}
// function checkFieldId(control: AbstractControl): ValidationErrors {
//   if (control.parent != undefined) {
//     var password: string = control.parent.get("id").value;
//   }
//   return null;
// }

 function replaceAll(str, from, to){
    var n = '';
    for(let g of str){
      if (g === from) {
        g = to;  
      }
      n += g;
    }
    return n;
  }
