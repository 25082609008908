import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { HttpService } from 'src/app/shared/service/http.service';
import { DataService } from 'src/app/shared/service/data.service';

@Component({
    selector: 'app-departments',
    templateUrl: './departments.component.html',
    styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit{
    keyword = 'name';
    departments: any;
    selectedData:any = {};
    areas: any;
    @Output() depSelected = new EventEmitter<Object | null>();
    @Input()  props:any

    constructor(
        private httpService:HttpService,
        public dataService:DataService,
    ){

    }
    ngOnInit(){
        this.getDepartments();
        this.init();
    }
    init(){
        if(this.props&&this.props.rowData){
            const {area, department} = this.props.rowData
            this.selectedData = {department,area};
        }
        if(this.props.type==='selectable'){
            // this.areas = this.dataService.areas.map(a=>{return {name:a.area}})
        }
    }
    async getDepartments(){
        // const {data}:any = await this.httpService.getDepartments();
        this.departments = this.dataService.departments
        // console.log('this.dataService.departments 1', data)
        // console.log('this.dataService.departments 2', this.dataService.departments)
    }
    setArea(departmentName:string){
        // this.areas = this.dataService.areas.filter(a=>a.department.name===departmentName).map(a=>{return {name:a.area}})
    }
    changeDepartmentEvent(event:any){
        this.selectedData.department = {name: event}
        if(event.name)this.emit();
        if(this.props&&this.props.type==='selectable'){ this.setArea(event.name) }
    }
    selectDepartmentEvent(event:any){
        if(event.name){
            this.selectedData.department = {id:event.id, name: event.name}
            this.emit()
            if(this.props&&this.props.type==='selectable'){ this.setArea(event.name) }
        };
    }
    departmentClearedEvent(event:any){
        this.selectedData = {};
        this.emit()
    }

    selectAreaEvent(event:any){
        if(event.name){
            this.selectedData.area = event.name
            this.emit()
        } 
    }
    areaClearedEvent(event:any){
        this.setArea(this.selectedData.department.name)
        this.selectedData.area = null;
        this.emit()
    }

    emit(){ 
        this.depSelected.emit(this.selectedData);
    }

}