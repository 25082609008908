import { Component } from '@angular/core';
import { DataService } from '../../shared/service/data.service';
import { HttpService } from '../../shared/service/http.service';
import { ButtonViewComponent } from './button-view/button-view.component'
import * as XLSX from 'xlsx'; 
interface indexTitlesInt{
  title: string;
  code: string;
  link?: string;
};

@Component({
  selector: 'app-home-admin',
  templateUrl: './home-admin.component.html',
  styleUrls: ['./home-admin.component.scss']
})
export class HomeAdminComponent {

  indexTitles: indexTitlesInt[] = [];
  loadedTitles: boolean = false;
  public settings = {
    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'right'
    },
    columns: {
      code: {
        title: 'Индекс'
      },
      title: {
        title: 'Номи',
        type: 'html'
      },
      link: {
        title: "ko'rish",
        type: 'custom',
        renderComponent: ButtonViewComponent
      }
    },
  };
  public indexTitle = [
    {title: 'code'},
    {title: 'title'},
    {title: 'link'}
  ]
  constructor(
    private dataService: DataService,
    private httpService: HttpService,
    ) {
  	this.getIndexTitles();
    // this.httpService.testtest2()
    // .subscribe(res=>{
    //   console.log('res res 23', res);
    // })
  }


  getIndexTitles(){
    this.dataService.field$.subscribe(fields=>{
      if (fields) {
        fields.forEach(field=>{
          if (field) {
            this.indexTitles.push({title: field['title'], code: replaceAll((field.id),"d", "."), link: `/indexes-data/${field['id']}`})
          }
        })
        this.loadedTitles = true;
      }else{
        alert("#578 fields empty")
      }
    })
  }
  replaceAll(str, from, to){
    var n = '';
    for(let g of str){
      if (g === from) {
        g = to;  
      }
      n += g;
    }
    return n;
  }
  // fileName= 'ExcelSheet.xlsx';  
  // exportexcel(): void {
  //    /* table id is passed over here */   
  //    let element = document.getElementById('excel-table'); 
  //    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

  //    /* generate workbook and add the worksheet */
  //    const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  //    /* save to file */
  //    XLSX.writeFile(wb, this.fileName);
    
  // }
}
function replaceAll(str, from, to){
    var n = '';
    for(let g of str){
      if (g === from) {
        g = to;  
      }
      n += g;
    }
    return n;
}