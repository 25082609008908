<!--                 <a style="cursor: pointer" (click)="exportexcel()">
                  cloud_download
                </a>
                <table id="excel-table"> 
                        <tr *ngFor="let tr of indexTitle">       
                           <th>{{tr.title}}</th> 
                        </tr>    
                        <tr *ngFor="let td of indexTitles">
                           <td>{{td.code}}</td>  
                           <td>{{td.title}}</td>  
                           <td>{{td.link}}</td>  
                        </tr> 
                </table> -->
<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-body vendor-table" >
            <div id="batchDelete" class="custom-datatable vendor-list">
                <div class="table-responsive" *ngIf="loadedTitles">
                        <ng2-smart-table  [settings]="settings" [source]="indexTitles"></ng2-smart-table>
                </div>
            </div>
        </div>
    </div>
</div>
    <!-- Container-fluid Ends-->
<!-- <div class="card-wrap">	
	<a class="card o-hidden widget-cards" *ngFor="let item of indexTitles" [routerLink]="'/create-index'">
	    <span class="bg-primary card-body">
			{{item.code}}
			{{item.title}}
	    </span>
	</a>
</div> -->
