import { Component, ViewChild } from "@angular/core";

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
})

export class InvoiceComponent {
  selectedItem: any = '';
  inputChanged: any = '';
  wikiItems: any[] = [];

  items2: any[] = [{id: 0, payload: {label: 'Tom'}},
    {id: 1, payload: {label: 'John'}},
    {id: 2, payload: {label: 'Lisa'}},
    {id: 3, payload: {label: 'Js'}},
    {id: 4, payload: {label: 'Java'}},
    {id: 5, payload: {label: 'c'}},
    {id: 6, payload: {label: 'vc'}}
  ];
  config2: any = {'placeholder': 'test', 'sourceField': ['payload', 'label']};

  constructor() {}

  onSelect(item: any) {
    this.selectedItem = item;
  }
 
  onInputChangedEvent(val: string) {
    this.inputChanged = val;
  }
  onBlurMethod(){
    (document.getElementsByTagName('ng2-input-autocomplete')as HTMLCollectionOf<HTMLElement>)[0].style.top = "30px";
  }
}
