import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { DataService } from 'src/app/shared/service/data.service';
import { HttpService } from 'src/app/shared/service/http.service';

export interface PromptModel {
  title:string;
  question:string;
}

@Component({
  selector: 'prompt',
  template: `
    <div class="modal-content">
      <div class="modal-header">
        <h4>Yo‘nalishni o'zgartirish</h4>
      </div>
      <div class="modal-body">
        <app-departments 
          [props]="{type: 'depsel'}" 
          (depSelected)="depSelected($event)"
        ></app-departments>
        <div class="modal-body_item mtop10">
          <label>Yo‘nalish</label>
          <div class="modal-body_item_sec red">
            <div *ngIf="errorArea" >{{errorArea}}</div>
            <input type="text" (input)="checkForSimilarArea($event)"  class="form-control" [(ngModel)]="rowData.name" name="name" />
          </div>
        </div>
        <div class="modal-body_item mtop10">
          <label>Xodimlar soni</label>
          <div class="modal-body_item_sec red">
            <div *ngIf="errorArea" >{{errorArea}}</div>
            <input type="number"  class="form-control" [(ngModel)]="rowData.numOfEmployees" name="numOfEmployees" />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" (click)="close()">Bekor qilish</button>
        <button type="button" class="btn btn-primary" [disabled]="!rowData.name || !rowData.departmentId || errorArea || errorAbb" (click)="apply()">Tasdiqlash</button>
      </div>
    </div>
  `,
  styles: [`
    .modal-body_item{
        display: flex;
        align-items: center;
        & label{
            flex: 0 0 30%;
          }
        & input{
            width: 100%;
        }
        &_sec{
          flex: 0 0 70%;
          width: 100%;
        }
    }
    .mtop10{
        margin-top:10px;
    }
    .modal-body_item_sec{
      width:100%;
    }
    .red{
      color: red;
    }
  `]
})
export class EditRowModalComponent extends SimpleModalComponent<PromptModel, string> implements PromptModel, OnInit {
  title: string = '';
  question: string = '';
  message: string = '';

  errorArea: string = '';
  errorAbb: string = '';
  rowData: any;

  constructor(
    private dataService:DataService,
    private httpService:HttpService,
  ) {
    super();
  }

  ngOnInit(){
    this.rowData = JSON.parse(this.question);
    console.log('rowData', this.rowData)
  }
  depSelected(event:any){
    this.rowData = {
      ...this.rowData, 
      departmentId:event.department.id,
      departmentName: event.department.name
    }
  }

  checkForSimilarArea(event:any){
    // this.rowData = JSON.parse(this.question);
    // const inputValue= event.srcElement.value;
    // if(this.dataService.areas.find((r:any) => r.area === inputValue && r.area !== rowData.area)){
    //   this.errorArea = 'Bunday nomli yo‘nalish kirizilgan';
    // }else{this.errorArea = '';}
  }
  checkForSimilarAbb(event:any){
    // const rowData = JSON.parse(this.question);
    // const inputValue= event.srcElement.value;
    // if(this.dataService.areas.find((r:any) => r.abbreviation === inputValue && r.abbreviation !== rowData.abbreviation)){
    //   this.errorAbb = 'Bunday nomli qisqartma kirizilgan';
    // }else{this.errorAbb = '';}
  }
  apply() {
    this.result = JSON.stringify(this.rowData);
    this.close();
  }
}
