import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirebaseService } from '../../shared/service/firebase.service';
import { DataService } from '../../shared/service/data.service';
// import { PvoKafInter } from '../../shared/interface/field.interface';

import firebase from "firebase/app";
import "firebase/database";
import { SimpleModalService } from 'ngx-simple-modal';
import { PromptComponent } from '../../shared/components/alert/prompt/prompt.component';

interface tmp{
  indexId: string;
  tableLoading: boolean;
  loadingRgba: boolean;
  emptyPage: boolean;
}

@Component({
  selector: 'app-check-ind-bkaf',
  templateUrl: './check-ind-bkaf.component.html',
  styleUrls: ['./check-ind-bkaf.component.scss']
})
export class CheckIndBkafComponent {
  // pvoKaf: PvoKafInter[];
  tmp: tmp = {
    indexId: '',
    tableLoading: true,
    loadingRgba: false,
    emptyPage: false
  }
  public settings = {
    // hideHeader: true;
    hideSubHeader: true,
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<div class="table_del">del</div>'
    },
    edit: {
      confirmSave: true,
    },
    actions: {
      add: false,
      edit: true,
      delete: true,
      position: 'right'
    },
    columns: {}
  };
  source;
  table = [];
  tableMain = [];

  constructor(
    private route: ActivatedRoute,
    private firebaseService: FirebaseService,
    private dataService: DataService,
    private SimpleModalService: SimpleModalService
    ) { 
      // var kafed;
      // this.route.paramMap.subscribe(params => {
      //   kafed = params.get('id');
      // });
      // dataService.checkFieldTable$.subscribe(res=>{
      //   if (res.data) {
      //       this.filterTable(res.data, kafed)
      //   }else{
      //     this.initializeTable();
      //   }
      // })
  }
  ngOnInit(): void {
  }
  filterTable(data, kafed){
    var pvoNames 
      this.dataService.pvoKaf$.subscribe(pvoNames=>{
        pvoNames = pvoNames;
      });
    this.table = data.filter((table, idx, sss)=>{
      if (pvoNames) {
        return pvoNames.forEach(pvo=>{
          if ((pvo.lname + pvo.fname + (pvo.patronymic?pvo.patronymic:'')).replace(/[ ,.]/g, "") === table.source[0].source.pvoNames.replace(/[ ,.]/g, ""))  {
            if (pvo.kafed === kafed) {
              return true;
            }else{
              return false;
            }
          }else{
              // console.log(3)
            return false;
          }
        })
      }else{
        return false
      }
      if (sss.length === (idx+1)) {
      }
    })
    // this.tmp.tableLoading = false;
  }
  // initializeTable(){
  //     this.dataService.checkField$.subscribe(res=>{
  //       if (res.data === 'empty') {
  //           this.tmp.emptyPage = true;
  //           this.tmp.tableLoading = false;
  //           this.tmp.loadingRgba = false;
  //       }else{
  //           this.initializeVar();
  //           if (res.data) {
  //             this.getTableData(res.data);
  //           }
  //       }
  //     })
  // }
  initializeVar(){
    this.tmp = {
      indexId: '',
      tableLoading: true,
      loadingRgba: false,
      emptyPage: false
    }
    this.settings = {
    // hideHeader: true;
      hideSubHeader: true,
      delete: {
        confirmDelete: true,
        deleteButtonContent: '<a class="table_del_changed_icon"></a>'
      },
      edit: {
        confirmSave: true,
      },
      actions: {
        add: false,
        edit: true,
        delete: true,
        position: 'right'
      },
      columns: {}
    };
    this.source = [];
  }
  // getTableData(data){
  //     this.tableMain = data;
  //     Object.keys(data).forEach(async (res, idx, sss)=>{
  //           this.tmp.emptyPage = false;
  //           this.initializeVar();
  //           await this.genTableSource(data[res])
  //           if (sss.length === (idx+1)) {              
  //             this.dataService.checkFieldTable$.next({data: this.table})
  //           }
  //     })
  // }
  onEditConfirm(event, source){
    this.tmp.loadingRgba = true;
    var arr = this.tableMain.filter((res, ind)=> ind.toString() === source.added_id)
    arr = arr[0];
    arr[(arr.length)-1].comment = event.newData.comment;
    arr[(arr.length)-1].status = "rejected";
    event.confirm.resolve();
    this.saveFormToDb(arr, source.indexId, source.added_id, "fieldsRejected", "small");
  }
  onSaveConfirm(event, source){
    this.SimpleModalService.addModal(PromptComponent, {
      title: 'Baholash',
      question: 'Ulushni kirgizing'})
      .subscribe((message) => {
        if (message) {
          var a = false;
          this.tmp.loadingRgba = true;
          this.dataService.pvoKaf$.subscribe(pvoData=>{
             pvoData.forEach((pvo, ss, sss)=>{
               var fullName = (pvo.lname + pvo.fname + (pvo.patronymic?pvo.patronymic:''));
                var arr = this.tableMain.filter((res, ind)=> ind.toString() === source.added_id);
                arr = arr[0];
                arr[arr.length-1].status = "complete"
                arr[arr.length-1]['grade'] = parseFloat(message);
                if (event.data.source.pvoNames && (event.data.source.pvoNames).replace(/ /g,'') === fullName.replace(/ /g,'')) {
                  this.saveFormToDb(arr, source.indexId, source.added_id, "fieldsInform", "big", {path: `pvoIns/${pvo.added_id}/${source.indexId}`, grade: parseFloat(message)});
                  a = true;
                }
                if ((sss.length === (ss+1)) && !a) {
                  this.saveFormToDb(arr, source.indexId, source.added_id, "fieldsInform", "big");
                }
             })
          })
        }
      });
  }
  saveFormToDb(arr, indexId, added_id, path, type, gradeToPvo?){
    this.firebaseService.incDb(`lastId/${path}/${type==="big"?indexId:""}`)
     .then((res)=>{
      this.firebaseService.getDoc(`lastId/${path}/${type==="big"?indexId:""}`).then((snapshot) => {
          if (snapshot.exists()) {
            arr[arr.length-1]['added_id'] = snapshot.val().last_id.toString();
            this.firebaseService.setDoc(`${path}/`+`${type==="big"?indexId:""}`+'/'+snapshot.val().last_id.toString(), arr)
            .then(()=>{
              this.firebaseService.removeDoc(`checkfield/${added_id}`).then(()=>{
                if (gradeToPvo && gradeToPvo.path) {
                  this.firebaseService.incDbValue(gradeToPvo.path, gradeToPvo.grade).then(()=>{
                    alert('Ma\'lumotlar muvaffaqiyatli saqlandi!')
                    this.tmp.loadingRgba = false;
                    this.table = this.table.filter(res=> (((res.source)[0]).source.added_id) !== added_id.toString());
                    this.dataService.checkFieldLength$.next(this.table.length)
                  }).catch((error)=>{
                    alert(error)
                  })
                }else{
                  alert('Ma\'lumotlar muvaffaqiyatli saqlandi!')
                  this.tmp.loadingRgba = false;
                  this.table = this.table.filter(res=> (((res.source)[0]).source.added_id) !== added_id.toString());
                  this.dataService.checkFieldLength$.next(this.table.length)
                }
              })
              .catch(error=>{
                alert(error)
              });
            }).catch(error=>{
              alert(error)
            });
          }else{
            alert("No data available");
          }
      }).catch((error) => {
         alert(error);
      });
    }).catch(error =>{
      alert(error)
    });
  }
  genTableSource(source){
    var arr = {};
    arr['indexId'] = replaceAll((source[(source.length)-1].indexId), "d", ".");
    arr['addBy'] = (source[(source.length)-1].user)
    source.forEach((dat, idx)=>{
      if (dat.type==="date" && dat.value) {
       arr[`title${idx}`] = `${(dat.value)['day']?(dat.value)['day']+'/':''}${(dat.value)['month']?(dat.value)['month']+'/':''}${(dat.value)['year']?(dat.value)['year']:''}`
      }else if (dat.type!=="file") {
        if (dat.addition && dat.addition === 'link' && dat.value) {
          arr[`title${idx}`] = `<a href="${dat.value}" target="_blank">giper xavola</a>`
        }else{

           arr[`title${idx}`] = dat.value?dat.value:'';
        }
      }else{
        arr[`title${idx}`] = '';  
        if (dat.value) {
          arr[`title${idx}`] = '<div class="file_download">';  
          dat.value.forEach((fet, indexFile)=>{
            arr[`title${idx}`] += `<a href="${fet.url}" target="_blank" class="file_download_link"><img src='assets/images/icon/file-icon.png'></a>`  
          })
          arr[`title${idx}`] += '</div>'
        }
      }
    })
    arr['source'] = source[source.length-1]
    this.source.push(arr);
    this.genTableSet((source[(source.length)-1].indexId));
  }
  genTableSet(indexId){
    indexId = indexId.replace("ddd", "d");
    this.dataService.field$.subscribe(fields=>{
      fields.forEach(field=>{
        if (field) {
          if (field.id === indexId) {
            this.settings.columns['indexId'] = {title: 'Индех', editable: false};
            field.config.forEach((res, idx)=>{
                this.settings.columns[`title${idx}`] = {title: res.label, type:res.label==="file"?'html':(res['addition'] && res['addition']==='link'?'html':'string'), editable: res.label==="file"?false:!res.comment?false:true};
            })
            this.settings.columns['addBy'] = {title: "Қўшди", editable: false}
            this.settings.columns['comment'] = {title: "Шархлар"}
            this.table.push({settings: this.settings, source: this.source})
            // this.dataService.checkField$.next(this.table)
          }
        }
      })
    })
  }
}
 function replaceAll(str, from, to){
    var n = '';
    for(let g of str){
      if (g === from) {
        g = to;  
      }
      n += g;
    }
    return n;
  }
