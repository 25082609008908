import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../shared/service/data.service';
import { AuthService } from '../../shared/service/auth.service';
import { ExcelService } from '../../shared/service/excel.service';
import * as XLSX from 'xlsx';
import { HttpService } from '../../shared/service/http.service';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { UpdateCitationComponent } from './update-citation.component';

interface tmp{
	indexId: string;
	tableLoading: boolean;
  fieldTitle?: string;
}
@Component({
  selector: 'app-indexes-data-pvo',
  templateUrl: './indexes-data-pvo.component.html',
  styleUrls: ['./indexes-data-pvo.component.scss']
})
export class IndexesDataPvoComponent implements OnInit {


  dataSource: LocalDataSource = new LocalDataSource();

  tmp: tmp = {
  	indexId: '',
  	tableLoading: true  
  }
  indexTitles = {};
  source;
  public settings = {
    pager: {
      display: true
    },
    actions: {
      add: false,
      edit: false,
      delete: true,
      position: 'right'
    },
    columns: {
    }
  };
  user;
  constructor(
  	private route: ActivatedRoute,
    private dataService: DataService,
  	private authService: AuthService,
    private excelService:ExcelService,
    private httpService: HttpService
  	) { 
  	this.route.paramMap.subscribe(params=>{
           this.user = this.dataService.user;
           // console.log('user:',this.user)
           if (this.user) {
         		this.initializeVar();
        		this.tmp.indexId = params.get('id');
        		this.getTable(params.get('id'));
           }
  	});
    this.updateCitation();
  }
  updateCitation(){
    this.dataService.updateCitationData
    .subscribe(res=>{
      // console.log('updateCitation', res)
      if (res) {
        var element = document.getElementsByClassName('loading_animation')[0];
        if(element)element.classList.remove("loading_animation");
        this.dataSource['data'].forEach(rowData=>{if (rowData.source.added_id === res.source.added_id)rowData = res;});
        this.dataSource.refresh();
        alert("Iqtiboslik muvaffaqiyat yangilandi!");
        this.dataService.updateCitationStatus = false;
      }
    });

  }
  ngOnInit(): void {
  }
  initializeVar(){
  	this.indexTitles = [];
  	this.tmp = {
  		indexId: '',
  	 	tableLoading: true
  	}
  	this.settings = {
        pager: {
          display: true
        },
  	    actions: {
  	      add: false,
  	      edit: false,
  	      delete: false,
  	      position: 'none'
  	    },
  	    columns: {
        }
  	};
  	this.source = [];
  }
  onDeleteConfirm(event){
    
  }

  fileName = 'ExcelSheet.xlsx';
  exportexcel(): void {
     /* table id is passed over here */   
      this.tmp.tableLoading = true;
      this.settings.pager.display = false;
      setTimeout(()=>{
        this.tmp.tableLoading = false;
        setTimeout(()=>{

            // this.excelService.exportAsExcelFile(document.getElementById('excelTable').children[0]);
             const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(document.getElementById('excelTable').children[0]);

             const wb: XLSX.WorkBook = XLSX.utils.book_new();
             XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

             XLSX.writeFile(wb, this.fileName);
        }, 1000)
      }, 1000)
    
  }
  getTable(id){
    this.httpService.getDoc(["fieldsInform", id]).subscribe((data:any) => {
  		if (data) {
  			this.genTableSource(data)
  		}else{
        this.genTableSet();
      }
  	}, error=>{
  		setTimeout(()=>{
  			this.getTable(id)
  		}, 1000)
  		console.log(error)
  	})

  }
  genTableSet(){
  	this.dataService.field$.subscribe(fields=>{
  		fields.forEach(field=>{
        if (field) {
    			if (field.id === this.tmp.indexId) {
            this.tmp.fieldTitle = field.title;
    				field.config.forEach((res, idx, sss)=>{
              if (res.type !== "button") {
                if (res.type!=="file") {
    		  				this.settings.columns[`title${idx}`] = {title: res.label};
    		  			}else{
    		  				this.settings.columns[`title${idx}`] = {title: res.label, type: 'html'};
    		  			}
              }
              if(sss.length === (idx+1)){
                this.settings.columns[`ulush`] = {title: "ulush"};
              }else if(idx === 0){
                this.settings.columns[`kafedra`] = {title: "Kafedra"};
              }
    			 	})
            // if(this.tmp.indexId === 'gh6gd5'){
            //   this.settings.columns['updateCitation'] = {
            //     title: 'Yangilash',
            //     type: 'custom',
            //     renderComponent: UpdateCitationComponent,
            //     editable: false
            //   }
            // }
            this.dataSource.load(this.source);
  				  this.tmp.tableLoading = false;
    				// this.indexTitles = {title0:{title: '1'},title1:{title:'2'}}
    				// this.settings.columns = this.indexTitles;
    				// this.genTableSource(source);
    			}
        }
  		})
  	})
  }
  genTableSource(source){
  	(Object.keys(source)).reverse().forEach(res=>{
  		var arr = {};
      var user = false;
      if (Array.isArray(source[res])) {
    		source[res].forEach((dat, idx, sss)=>{
          // if ((sss[(sss.length-1)]).user === this.user.email)  {
            user = true;
      			if (dat.type==="date" && dat.value) {
              arr[`title${idx}`] = `${(dat.value)['day']?(dat.value)['day']+'/':''}${(dat.value)['month']?(dat.value)['month']+'/':''}${(dat.value)['year']?(dat.value)['year']:''}`
            }else if (dat.type!=="file") {
      				arr[`title${idx}`] = dat.value?dat.value:'';
      			}else{
    	  			arr[`title${idx}`] = '';	
      				if (dat.value) {
    		  			arr[`title${idx}`] = '<div class="file_download">';	
    	  				dat.value.forEach((fet, indexFile)=>{
    	  					arr[`title${idx}`] += `<a href="${fet.url}" target="_blank" class="file_download_link"><img src='assets/images/icon/file-icon.png'></a>`	
    	  				})
    	  				arr[`title${idx}`] += '</div>'
      				}
      			}
            if(sss.length === (idx+1)){
              arr['ulush'] = dat.grade?dat.grade:'';
            }
          // }
  		  })
      }
      if (user) {
        arr['source'] = source[`${res}`][(source[`${res}`].length-1)]
  	  	this.source.push(arr);
      }
  	})
  		this.genTableSet();
	// source.forEach(res=>{
	// 	var arr = {};
	// 	res.forEach((dat, idx)=>{
	// 		arr[`title${idx}`] = dat.value;
	// 	})
	//   	this.source.push(arr);
	// 	this.tmp.tableLoading = false;
	// })
  }
  replaceAll(str, from, to){
    var n = '';
    for(let g of str){
      if (g === from) {
        g = to;  
      }
      n += g;
    }
    return n;
  }
}
