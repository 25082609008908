import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/shared/service/data.service';

export interface PromptModel {
  title:string;
  question:string;
}

@Component({
  selector: 'prompt',
  templateUrl: './edit-row.component.html',
  // template: `

  // `,
  styleUrls: ['./add-row.component.scss'],
  // styles: [`

  // `]
})
export class EditRowComponent extends SimpleModalComponent<PromptModel, string> implements PromptModel, OnInit {
  title: string = '';
  question: string = '';
  message: string = '';

  area:string ='';
  abbreviation:string ='';
  errorFaculty:string = '';


  public form: FormGroup = this.formBuilder.group({});
  ngOnInit(){
    let rowData = JSON.parse(this.question);
    delete rowData.file_name;
    this.form.patchValue({id:rowData.id, name:rowData.name});
  }
  constructor(
    private formBuilder: FormBuilder,
    public dataService: DataService,
    ) {
    super();
    this.createAccountForm();
  }
  apply() {
    this.result = JSON.stringify(this.form.value);
    this.close();
  }
  checkForSimilarFaculty(event:any){
    const inputValue= event.srcElement.value;
    if(this.dataService.departments.find((r:any) => r.name === inputValue)){
      this.errorFaculty = 'Bunday fakultet kirizilgan';
    }else{this.errorFaculty = '';}
  }
  onAdd(event:any){}

  createAccountForm() {
    this.form = this.formBuilder.group({
      id:[],
      name: ['', Validators.required],
    })
  }
}